import {FC, useState} from 'react'
import {FormText, FormTextProps} from './FormText'

export interface FormPasswordProps extends FormTextProps {
  defaultVisibility?: boolean
}

const FormPassword: FC<FormPasswordProps> = ({defaultVisibility = false, ...rest}) => {
  const [visible, setVisible] = useState(defaultVisibility)

  return (
    <div data-testid='form-password-test'>
      <FormText
        type={visible ? 'text' : 'password'}
        appendInnerIcon={visible ? 'IconEyeOff' : 'IconEyeOn'}
        clickAppendInnerIcon={() => setVisible(!visible)}
        appendInnerClassname='text-neutral-60'
        {...rest}
      />
    </div>
  )
}

export {FormPassword}
