import clsx from 'clsx'
import {FC, ReactNode} from 'react'
import GIcon, {GIconProps} from '../Icon/GIcon'

enum GAlertVariant {
  primary = 'text-primary bg-primary-light-2',
  danger = 'text-danger bg-danger-light-2',
  warning = 'text-warning bg-warning-light-2',
  info = 'text-info bg-info-light-2',
  success = 'text-success bg-success-light-2',
}

enum GAlertIcon {
  primary = 'IconWarning',
  danger = 'IconWarning',
  warning = 'IconWarning',
  info = 'IconWarning',
  success = 'IconWarning',
}

type Props = {
  children?: ReactNode
  variant?: 'primary' | 'danger' | 'warning' | 'info' | 'success'
  icon?: GIconProps
  textClassname?: string
  iconClassname?: string
  className?: string
}

const GAlert: FC<Props> = ({
  children,
  variant = 'danger',
  icon,
  className = '',
  textClassname = '',
  iconClassname = '',
}) => {
  return (
    <div
      data-testid='galert-test'
      className={clsx('flex items-start rounded py-2 px-3', GAlertVariant[variant], className)}
    >
      <div className={clsx('w-20px h-20px mr-3', iconClassname)}>
        <GIcon data-testid='galert-test-icon' icon={icon ?? GAlertIcon[variant]} />
      </div>
      <div className={clsx('text-fs-9 font-medium', textClassname)}>{children}</div>
    </div>
  )
}

export default GAlert
