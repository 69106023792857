import clsx from 'clsx'
import {components, ControlProps, GroupBase} from 'react-select'
import {FormCheckbox} from '../Collection'

const Control = <
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
>({
  children,
  isFocused,
  isDisabled,
  ...rest
}: ControlProps<Option, IsMulti, Group>) => (
  <components.Control
    className={clsx('shadow-none bg-neutral-10 min-h-[44px]', {
      focus: isFocused,
      disabled: isDisabled,
    })}
    isFocused
    isDisabled
    {...rest}
  >
    {children}
  </components.Control>
)

const IndicatorSeparator = () => null

const Option = (props: any) => (
  <components.Option {...props}>
    <div className='flex items-center'>
      <FormCheckbox variant='classic' checked={props.isSelected} className='min-h-0 mr-4' />
      {props.label}
    </div>
  </components.Option>
)

export interface LabelValueProps {
  label?: string
  value?: string
}

export interface IdNameProps {
  id?: string
  name?: string
}

export const CustomReactSelect = {
  Control,
  IndicatorSeparator,
}

export const CustomReactMultiSelect = {
  Control,
  Option,
  IndicatorSeparator,
}
