import CreateNewPassword from "./page/CreateNewPassword/CreateNewPassword";
import CreateNewPasswordEmployee from "./page/CreateNewPasswordEmployee/CreateNewPasswordEmployee";

import ForgotPassword from "./page/ForgotPassword/ForgotPassword";
import LoginEmail from "./page/LoginEmail/LoginEmail";

const AuthScreens = {
  LOGIN_EMAIL: {
    COMPONENT: LoginEmail,
    PATH: "/auth/login-email",
  },
  FORGOT: {
    COMPONENT: ForgotPassword,
    PATH: "/auth/forgot-password",
  },
  CREATE_PASSWORD: {
    COMPONENT: CreateNewPassword,
    PATH: "/auth/reset-password",
  },
  CREATE_PASSWORD_EMPLOYEE: {
    COMPONENT: CreateNewPasswordEmployee,
    PATH: "/auth/set-password",
  },
};

export default AuthScreens;
