const BroadcastScreens = {
  BROADCAST: {
    PATH: '/broadcast',
    TITLE: 'Broadcast Notifikasi',
    TITLE_ALT: 'Broadcast Notifikasi',
  },
  LIST_BROADCAST: {
    PATH: '/broadcast/list',
    TITLE: 'Broadcast Notifikasi',
    TITLE_ALT: 'Broadcast Notifikasi',
    PERMISSION: 'broadcast_notification.read',
  },
  ADD_BROADCAST: {
    PATH: '/broadcast/list/add',
    TITLE: 'Tambah Data Broadcast Notifikasi',
    TITLE_ALT: 'Tambah Data Broadcast Notifikasi',
    PERMISSION: 'broadcast_notification.create',
  },
  DETAIL_BROADCAST: {
    PATH: '/broadcast/list/detail/:id',
    TITLE: 'Detail Broadcast Notifikasi',
    TITLE_ALT: 'Detail Broadcast Notifikasi',
    PERMISSION: 'broadcast_notification.read',
  },
}

export default BroadcastScreens
