import {defaultBreadcrumbs} from 'src/app/models/screen-prop'

const CampaignAndPromoScreens = {
  LOYALTYANDPROMO: {
    PATH: '/challenges-and-points',
    TITLE: 'Challenges & Points',
    TITLE_ALT: 'Campaign & Points',
    PERMISSION: 'challenge.read',
    breadcrumbs: defaultBreadcrumbs,
  },
  CHALLENGE: {
    PATH: '/challenges-and-points/challenge',
    TITLE: 'Challenges',
    TITLE_ALT: 'Challenge',
    PERMISSION: 'challenge.read',
    breadcrumbs: defaultBreadcrumbs,
  },
  CHALLENGE_ADD: {
    PATH: '/challenges-and-points/challenge/add',
    TITLE: 'Buat Data Challenge',
    TITLE_ALT: 'Buat Data Challenge',
    PERMISSION: 'challenge.create',
    breadcrumbs: defaultBreadcrumbs,
  },
  CHALLENGE_EDIT: {
    PATH: '/challenges-and-points/challenge/edit/:id',
    TITLE: 'Edit Data Challenge',
    TITLE_ALT: 'Edit Data Challenge',
    PERMISSION: 'challenge.update',
    breadcrumbs: defaultBreadcrumbs,
  },
  CHALLENGE_DETAIL: {
    PATH: '/challenges-and-points/challenge/detail/:id',
    TITLE: 'Detail Data Challenge',
    TITLE_ALT: 'Detail Data Challenge',
    PERMISSION: 'challenge.read',
    breadcrumbs: defaultBreadcrumbs,
  },
  EARNING_RATE: {
    PATH: '/challenges-and-points/earning-rate',
    TITLE: 'Earning Rate',
    TITLE_ALT: 'Earning Rate',
    PERMISSION: 'earning_rate.read',
    breadcrumbs: defaultBreadcrumbs,
  },
  EARNING_RATE_EDIT: {
    PATH: '/challenges-and-points/earning-rate/edit/:id',
    TITLE: 'Earning Rate',
    TITLE_ALT: 'Earning Rate',
    PERMISSION: 'earning_rate.update',
    breadcrumbs: defaultBreadcrumbs,
  },
  POINT_EXPIRATION: {
    PATH: '/challenges-and-points/point-expiration',
    TITLE: 'Point Expiration',
    TITLE_ALT: 'Point Expiration',
    PERMISSION: 'point_expiration.read',
    breadcrumbs: defaultBreadcrumbs,
  },
  POINT_EXPIRATION_EDIT: {
    PATH: '/challenges-and-points/point-expiration/edit/:id',
    TITLE: 'Edit Point Expiration',
    TITLE_ALT: 'Edit Point Expiration',
    PERMISSION: 'point_expiration.update',
    breadcrumbs: defaultBreadcrumbs,
  },
}

export default CampaignAndPromoScreens
