import {decryptData, encryptData} from './crypt-utils'

export const ENV_STORAGE_KEY = '_cons'
const VERSION = '1.0' //increase when there is new variable in server
const EXPIRY = 30000 //in milliseconds
const KEY_LENGTH = 12 //multiplied by 6
export let env: Record<string, string> = {}

interface StorageData {
  env: Record<string, string>
  updated: number
  version: string
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const loadEnvLocal = async () => {
  try {
    const enc = localStorage.getItem(ENV_STORAGE_KEY)
    if (enc) {
      const splits = enc.split('.')
      const key = atob(splits[2] + splits[0])
      const response: StorageData = JSON.parse(await decryptData(splits[1], key))
      if (Date.now() - response.updated < EXPIRY && response.version === VERSION) {
        env = response.env
        return true
      }
    }
  } catch (e) {}
  return false
}

function dec2hex(dec: number) {
  return dec.toString(16).padStart(2, '0')
}

function generateId(len: number) {
  const arr = new Uint8Array((len || 40) / 2)
  window.crypto.getRandomValues(arr)
  return Array.from(arr, dec2hex).join('')
}

const saveEnv = async () => {
  const key = generateId(KEY_LENGTH)
  const b64key = btoa(key)
  const encrypted = await encryptData(
    JSON.stringify({
      env,
      updated: Date.now(),
      version: VERSION,
    }),
    key
  )
  localStorage.setItem(
    ENV_STORAGE_KEY,
    `${b64key.substring(b64key.length / 2)}.${encrypted}.${b64key.substring(0, b64key.length / 2)}`
  )
}

export const loadEnv = async (force: boolean = false) => {
  env = process.env as any
  await saveEnv()
}
