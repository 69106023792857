import {combineReducers} from 'redux'
import {all} from 'redux-saga/effects'

import AuthRedux from 'src/app/modules/auth/redux/AuthRedux'
import DashboardRedux from 'src/app/modules/dashboard/redux/dashboard-redux'

export const rootReducer = () =>
  combineReducers({
    auth: AuthRedux.reducer(),
    dashboad: DashboardRedux.reducer,
  })

export type RootState = ReturnType<ReturnType<typeof rootReducer>>

export function* rootSaga() {
  yield all([AuthRedux.saga()])
}
