import {FC} from 'react'
import InlineSVG from 'react-inlinesvg'
import {toAbsoluteUrl} from 'src/app/utils/asset-helpers-utils'

export enum GIconCollection {
  IconAdd = '/media/icons/IconAdd.svg',
  IconCircleAdd = '/media/icons/IconCircleAdd.svg',
  IconArrowTop = '/media/icons/IconArrowTop.svg',
  IconBack = '/media/icons/IconBack.svg',
  IconBurger = '/media/icons/IconBurger.svg',
  IconCalendar = '/media/icons/IconCalendar.svg',
  IconCheck = '/media/icons/IconCheck.svg',
  IconCheckCircle = '/media/icons/IconCheckCircle.svg',
  IconClose = '/media/icons/IconClose.svg',
  IconCloseRound = '/media/icons/IconCloseRound.svg',
  IconDocumentDownload = '/media/icons/IconDocumentDownload.svg',
  IconDoubleChevronLeft = '/media/icons/IconDoubleChevronLeft.svg',
  IconDown = '/media/icons/IconDown.svg',
  IconDelete = '/media/icons/IconDelete.svg',
  IconEdit = '/media/icons/IconEdit.svg',
  IconEyeOn = '/media/icons/IconEyeOn.svg',
  IconEyeOff = '/media/icons/IconEyeOff.svg',
  IconEnvelope = '/media/icons/IconEnvelope.svg',
  IconExclamation = '/media/icons/IconExclamation.svg',
  IconFilter = '/media/icons/IconFilter.svg',
  IconFileCSV = '/media/icons/IconFileCSV.svg',
  IconImagePlaceHolder = '/media/icons/IconImagePlaceHolder.svg',
  IconInfo = '/media/icons/IconInfo.svg',
  IconLaptop = '/media/icons/IconLaptop.svg',
  IconLoading = '/media/icons/IconLoading.svg',
  IconMoon = '/media/icons/IconMoon.svg',
  IconPaginationRight = '/media/icons/IconPaginationRight.svg',
  IconPaginationLeft = '/media/icons/IconPaginationLeft.svg',
  IconPaperPlane = '/media/icons/IconPaperPlane.svg',
  IconRefresh = '/media/icons/IconRefresh.svg',
  IconSearch = '/media/icons/IconSearch.svg',
  IconSun = '/media/icons/IconSun.svg',
  IconTrash = '/media/icons/IconTrash.svg',
  IconUserFriends = '/media/icons/IconUserFriends.svg',
  IconUp = '/media/icons/IconUp.svg',
  IconUpDown = '/media/icons/IconUpDown.svg',
  IconUpload = '/media/icons/IconUpload.svg',
  IconSignOut = '/media/icons/IconSignOut.svg',
  IconView = '/media/icons/IconView.svg',
  IconWarning = '/media/icons/IconWarning.svg',
  IconLeftRow = '/media/icons/IconLeftRow.svg',
  IconInstagram = '/media/icons/IconInstagram.svg',
  IconWeb = '/media/icons/IconWeb.svg',
  IconTwitter = '/media/icons/IconTwitter.svg',
  IconFacebook = '/media/icons/IconFacebook.svg',
  IconWarningDanger = '/media/icons/IconWarningDanger.svg',
  IconChecklist = '/media/icons/IconChecklist.svg',
  IconFilterActive = '/media/icons/IconFilterActive.svg',
  IconLight = '/media/icons/IconLight.svg',
  IconUploadRed = '/media/icons/IconUploadRed.svg',
  IconLoadingMakan = '/media/icons/IconLoadingMakan.svg',
  IconLocation = '/media/icons/IconLocation.svg',
  IconClock = '/media/icons/IconClock.svg',
  IconTiktok = '/media/icons/IconTiktok.svg',
  IconPhotoDefault = '/media/icons/IconPhotoDefault.svg',
  IconPDF = '/media/icons/IconPDF.svg',
  IconCloseBG = '/media/icons/IconCloseBG.svg',
  IconDeletDanger = '/media/icons/IconDeletDanger.svg',
  IconBoxsAction = '/media/icons/IconBoxsAction.svg',
  IconDirections = '/media/icons/IconDirections.svg',
  IconDollar = '/media/icons/IconDollar.svg',
  IconBuy = '/media/icons/IconBuy.svg',
  IconStore = '/media/icons/IconStore.svg',
  IconStartYellow = '/media/icons/IconStartYellow.svg',
  IconStartEmpty = '/media/icons/IconStartEmpty.svg',
  IconExportFile = '/media/icons/IconExportFile.svg',
  IconEditWhite = '/media/icons/IconEditWhite.svg',
  IconUserManagement = '/media/icons/IconUserManagement.svg',
  IconHomeMakanYa = '/media/icons/IconHomeMakanYa.svg',
  IconChatReview = '/media/icons/IconChatReview.svg',
  IconInfoCircle = '/media/icons/IconInfoCircle.svg',
  IconUsedDollar = '/media/icons/IconUsedDollar.svg',
  IconExpired = '/media/icons/IconExpired.svg',
  IconDollarDouble = '/media/icons/IconDollarDouble.svg',
  IconNominal = '/media/icons/IconNominal.svg',
  IconTooltips = '/media/icons/IconTooltips.svg',
}

export type GIconProps = keyof typeof GIconCollection

type Props = {
  icon: GIconProps
  className?: string
  onClick?: () => void
}

const GIcon: FC<Props> = ({icon, className, onClick}) => {
  return (
    <InlineSVG src={toAbsoluteUrl(GIconCollection[icon])} className={className} onClick={onClick} />
  )
}

export default GIcon
