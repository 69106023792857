import {useFormik} from 'formik'
import {FC, useEffect, useState} from 'react'
import {useDispatch} from 'react-redux'
import {Link} from 'react-router-dom'
import GAlert from 'src/app/components/Libs/Alert/GAlert'
import GButton from 'src/app/components/Libs/Button/GButton'
import {FormLabel, FormPassword, FormText} from 'src/app/components/Libs/Form/Collection'
import {toAbsoluteUrl} from 'src/app/utils/asset-helpers-utils'
import {imageError} from 'src/app/utils/broken-image'
import {getTitle} from 'src/app/utils/title-utils'
import * as Yup from 'yup'
import AuthScreens from '../../Screens'
import AuthRedux from '../../redux/AuthRedux'
import {loginByEmail} from '../../services/Auth.services'

const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email('Format email tidak sesuai')
    .required('Email wajib diisi')
    .matches(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/, 'Format email tidak sesuai'),
  password: Yup.string().required('Password wajib diisi'),
})

const initialValues = {
  email: '',
  password: '',
}

const LoginEmail: FC = () => {
  const [loading, setLoading] = useState<boolean>(false)
  const dispatch = useDispatch()

  const formik = useFormik({
    initialValues,
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: loginSchema,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      setTimeout(async () => {
        try {
          const result = await loginByEmail(values)
          const data = result.data.response_output.detail
          const {token, refresh_token} = data

          dispatch(AuthRedux.actions.login(token, refresh_token))
        } catch (e: any) {
          const status = e.response.status
          const response = e.response.data?.response_output.errors
          if (status >= 500) {
            setStatus('Layanan sementara tidak tersedia. Coba lagi nanti.')
          } else {
            setStatus(response[0].message)
          }
        } finally {
          setLoading(false)
          setSubmitting(false)
        }
      }, 1000)
    },
  })

  useEffect(() => {
    document.title = getTitle('Login')
  }, [])

  return (
    <>
      <div className='w-full' data-testid='login-email-page'>
        <div className='mb-10 text-center'>
          <div className='flex items-center justify-center'>
            <img
              src={toAbsoluteUrl('/media/logos/Logo-Makan.svg')}
              className='h-auto text-center'
              alt='logo'
              onError={(e) => imageError(e)}
            />
          </div>
          <div className='text-neutral-80 pt-2 font-bold'>Login Akun</div>
        </div>

        {formik.status && <GAlert className='mb-8'>{formik.status}</GAlert>}

        <form className='w-full' onSubmit={formik.handleSubmit} noValidate>
          <div className='mb-8'>
            <FormLabel required className='mb-2'>
              Email
            </FormLabel>
            <FormText
              {...formik.getFieldProps('email')}
              name='email'
              type='email'
              placeholder='Harap masukkan email Anda'
              maxLength={50}
              error={formik.errors.email}
              touched={formik.touched.email}
            />
          </div>

          <div className='mb-8'>
            <FormLabel required className='mb-2'>
              Password
            </FormLabel>
            <FormPassword
              {...formik.getFieldProps('password')}
              name='password'
              placeholder='Harap masukkan password Anda'
              minLength={8}
              error={formik.errors.password}
              touched={formik.touched.password}
            />

            <div className='pb-2 mt-4 font-medium text-end text-[#172554] text-fs-9'>
              <Link to={AuthScreens.FORGOT.PATH}>
                <span className='cursor-pointer'>Lupa password?</span>
              </Link>
            </div>
          </div>

          <GButton
            type='submit'
            size='large'
            className='w-full bg-danger border-none'
            loading={loading}
          >
            Masuk
          </GButton>
        </form>
      </div>
    </>
  )
}

export default LoginEmail
