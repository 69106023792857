import {Editor} from '@tinymce/tinymce-react'

import clsx from 'clsx'

import {FC} from 'react'

export interface FormEditorProps {
  value: string

  onChange: (value: string, editor: any) => void

  placeholder: string

  error?: string

  touched?: boolean

  disabled?: boolean

  toolbar?: string
}

const FormEditor: FC<FormEditorProps> = ({
  onChange,
  value,
  placeholder,
  touched,
  error,
  disabled,
  toolbar,
}) => {
  return (
    <div>
      <div
        data-testid='formeditor-test'
        className={clsx('w-100 rounded-lg', {
          'border border-danger': error && touched,
        })}
      >
        <Editor
          value={value}
          onEditorChange={onChange}
          disabled={disabled}
          init={{
            height: 140,
            link_title: false,
            target_list: false,
            menubar: false,

            placeholder: `${placeholder}`,

            plugins: [
              'advlist autolink lists link image charmap print preview anchor',

              'searchreplace visualblocks code fullscreen',

              'insertdatetime media table paste code help wordcount',
            ],

            toolbar: toolbar
              ? toolbar
              : `formatselect | bold italic link bullist numlist | indent outdent | image blockquote table media undo redo`,
          }}
          tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce/tinymce.min.js'}
        />
      </div>

      {touched && error && <div className='mt-2 font-medium text-danger text-fs-10'>{error}</div>}
    </div>
  )
}

export {FormEditor}
