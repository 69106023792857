import {Suspense, lazy} from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import {FallbackView} from '../components/Layout/FallbackView'
import AuditTrailScreens from '../modules/audit-trail/Screens'
import BrandAndOutletScreens from '../modules/brand-and-outlet/BrandAndOutletScreens'
import BroadcastScreens from '../modules/broadcast-notification/Screens'
import CampaignAndPromoScreens from '../modules/campaign-and-points/CampaignAndPromoScreens'
import DashboardScreens from '../modules/dashboard/Screens'
import ProfileScreens from '../modules/profile/Screens'
import ReportScreens from '../modules/report/Screens'
import ReviewAndFeedbackScreens from '../modules/review-and-feedback/ReviewAndFeedbackScreens'
import RewardAndVoucherScreens from '../modules/reward-and-voucher/RewardAndVoucher'
import SettingsScreens from '../modules/settings/Screens'
import TierAndBenefitScreens from '../modules/tier-benefit/Screens'
import TransactionScreens from '../modules/transaction/TransactionScreens'
import UserManagementScreens from '../modules/user-management/Screens'

export function PrivateRoutes() {
  const DashboardPage = lazy(() => import('../modules/dashboard/DashboardRoutes'))
  const UserManagementPage = lazy(() => import('../modules/user-management/UserManagementRoutes'))
  const SettingsPage = lazy(() => import('../modules/settings/SettingsRoutes'))
  const BrandAndOutletPage = lazy(() => import('../modules/brand-and-outlet/BrandAndOutletRoutes'))
  const CampaignAndPromoPage = lazy(
    () => import('../modules/campaign-and-points/CampaignAndPromoRoutes')
  )
  const BroadcastPage = lazy(() => import('../modules/broadcast-notification/BroadcastRoutes'))
  const ReportPage = lazy(() => import('../modules/report/ReportRoutes'))
  const ReviewAndFeedbackPage = lazy(
    () => import('../modules/review-and-feedback/ReviewAndFeedbackRouter')
  )
  const TransactionPage = lazy(() => import('../modules/transaction/TransactionRoutes'))
  const RewardAndVoucherPage = lazy(
    () => import('../modules/reward-and-voucher/RewardAndVoucherRoutes')
  )
  const TierBenefitPage = lazy(() => import('../modules/tier-benefit/TierAndBenefitRoutes'))
  const ProfilePage = lazy(() => import('../modules/profile/ProfileRoutes'))
  const AuditTrailPage = lazy(() => import('../modules/audit-trail/AuditTrailRoutes'))

  return (
    <>
      <Suspense fallback={<FallbackView />}>
        <Switch>
          <Route path={DashboardScreens.DASHBOARD.PATH} component={DashboardPage} />
          <Route path={UserManagementScreens.USER_MANAGEMENT.PATH} component={UserManagementPage} />
          <Route path={SettingsScreens.SETTINGS.PATH} component={SettingsPage} />
          <Route path={BrandAndOutletScreens.BRANDSANDOUTLET.PATH} component={BrandAndOutletPage} />
          <Route
            path={CampaignAndPromoScreens.LOYALTYANDPROMO.PATH}
            component={CampaignAndPromoPage}
          />
          <Route path={BroadcastScreens.BROADCAST.PATH} component={BroadcastPage} />
          <Route path={ReportScreens.REPORT.PATH} component={ReportPage} />
          <Route
            path={ReviewAndFeedbackScreens.RIVIEWANDFEEDBACK.PATH}
            component={ReviewAndFeedbackPage}
          />
          <Route path={TierAndBenefitScreens.TIER_BENEFIT.PATH} component={TierBenefitPage} />
          <Route path={TransactionScreens.TRANSACTION_SCREENS.PATH} component={TransactionPage} />
          <Route
            path={RewardAndVoucherScreens.REWARDANDVOUCHER.PATH}
            component={RewardAndVoucherPage}
          />

          <Route path={AuditTrailScreens.AUDIT_TRAIL.PATH} component={AuditTrailPage} />
          <Route path={ProfileScreens.PROFILE.PATH} component={ProfilePage} />
          <Redirect from='/auth' to='/dashboard' />
          <Redirect exact from='/' to='/dashboard' />
          <Redirect to='error/404' />
        </Switch>
      </Suspense>
    </>
  )
}
