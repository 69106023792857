import clsx from 'clsx'
import React, {Children, useMemo, useState} from 'react'
import {useLocation} from 'react-router'
import {useMasterLayout} from 'src/app/context/MasterContext'
import {checkIsActive} from 'src/app/utils/router-helpers-utils'
import {KTSVG} from '../../Libs/Icon/KTSVG'

type Props = {
  children?: any
  to: string
  title: string
  icon?: string
  fontIcon?: string
  hasBullet?: boolean
  activePath?: string
}

const AsideMenuItemWithSub: React.FC<Props> = ({
  children,
  to,
  title,
  icon,
  fontIcon,
  hasBullet,
}) => {
  const {pathname} = useLocation()
  const isActive = checkIsActive(pathname, to)
  const {minimize} = useMasterLayout()
  const splitPathname = '/' + pathname.split('/')[1]
  const [open, setOpen] = useState<string>(splitPathname)

  const handleOpen = (value: string) => {
    setOpen(open === value ? '' : value)
  }

  const countArray = Children.toArray(children).length
  const newMaxHeight = useMemo(() => `h-[${3 * countArray}rem]`, [countArray])

  return (
    <div
      className={clsx('p-0 block overflow-hidden', {
        'overflow-visible': to,
      })}
      onClick={() => handleOpen(to)}
    >
      <span
        className={clsx(
          'bg-none p-3 mx-3 cursor-pointer flex items-center',
          'hover:text-primary-light-2',
          {
            'text-primary-light-2': open,
            'text-neutral-50 ': !open,
          }
        )}
      >
        {hasBullet && (
          <span className='flex items-center justify-center w-6 mr-2 shrink-0'>
            <span
              className={clsx('w-1 h-1 rounded-full', 'hover:bg-primary-light-2', {
                'bg-primary-light-2': isActive,
                'bg-neutral-50': !isActive,
              })}
            ></span>
          </span>
        )}
        {icon && (
          <span className='flex items-center w-8 shrink-0'>
            <KTSVG path={icon} className='w-6 h-6' />
          </span>
        )}
        {/* still bootstrap style / change it !  */}
        {fontIcon && <i className={clsx('bi fs-3', fontIcon)}></i>}
        <span
          className={clsx('flex items-center flex-1 transition-all duration-300', {
            'opacity-0 group-hover:opacity-100 truncate': minimize,
          })}
        >
          {title}
        </span>
        <span
          className={clsx(
            'flex items-stretch relative overflow-hidden ml-2 w-[0.8rem] h-[0.8rem] transition ease-in',
            'after:block after:content-[""] after:w-full after:bg-[url("/public/media/icons/IconChevronRight.svg")] after:bg-center after:bg-no-repeat',
            {'rotate-90': open === to}
          )}
        ></span>
      </span>
      <div
        onClick={(e) => e.stopPropagation()}
        className={clsx(
          `ml-2 pl-3 rounded-lg transition-all overflow-hidden duration-300 h-auto ease-in ${
            open === to && newMaxHeight
          }`,
          {'h-0 ': open !== to},
          {'ml-0 mr-0 pl-0 group-hover:ml-2 group-hover:pl-3': minimize}
        )}
      >
        {children}
      </div>
    </div>
  )
}

export {AsideMenuItemWithSub}
