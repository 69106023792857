import {FC} from 'react'
import {shallowEqual, useSelector} from 'react-redux'
import {Redirect, Route, Switch, useLocation} from 'react-router-dom'
import {RootState} from 'src/setup'
import MasterLayout from '../components/Layout/MasterLayout'
import {HeaderToast} from '../components/Libs/Toast/GToast'
import {HeaderToastProvider} from '../context/ToastContext'
import AuthRoutes from '../modules/auth/AuthRoutes'
import AuthScreens from '../modules/auth/Screens'
import GuestWrapper from '../modules/auth/components/GuestWrapper'
import {Logout} from '../modules/auth/page/Logout/Logout'
import {PrivateRoutes} from './PrivateRoutes'

const Routes: FC = () => {
  const accessToken: any = useSelector<RootState>(
    ({auth}) => Boolean(auth.accessToken),
    shallowEqual
  )
  let local = localStorage.getItem('users')

  const location = useLocation()
  const path = location.pathname.split('/')
  let resetPasswordText = '/' + path[1] + '/' + path[2]

  return (
    <Switch>
      {resetPasswordText === '/auth/reset-password' && (
        <HeaderToastProvider>
          <HeaderToast auth={true} />
          <GuestWrapper>
            <Route
              path={AuthScreens.CREATE_PASSWORD.PATH}
              component={AuthScreens.CREATE_PASSWORD.COMPONENT}
            />
          </GuestWrapper>
        </HeaderToastProvider>
      )}

      {!accessToken ? (
        /*Render auth page when user at `/auth` and not authorized.*/
        <Route path='/auth'>
          <AuthRoutes />
        </Route>
      ) : (
        /*Otherwise redirect to root page (`/`)*/
        <Redirect from='/auth' to='/' />
      )}

      {/* <Route path='/error' component={ErrorsPage} /> */}
      <Route path='/logout' component={Logout} />

      {!accessToken ? (
        <Redirect to='/auth/login-email' />
      ) : (
        <>
          {local ? (
            <MasterLayout>
              <PrivateRoutes />
            </MasterLayout>
          ) : (
            window.location.reload()
          )}
        </>
      )}
    </Switch>
  )
}

export {Routes}
