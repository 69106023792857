import {useCallback, useMemo} from 'react'
import {shallowEqual, useSelector} from 'react-redux'
import {useHistory} from 'react-router-dom'
import {RootState} from 'src/setup'
import {ModulePermissionQuery} from '../modules/user-management/models/Role.model'
// import {ModulePermissionQuery} from '../modules/permissions/models/Role.model'

export function usePermissions(pageQuery?: ModulePermissionQuery) {
  const history = useHistory()
  const permissions = useSelector<RootState>(
    ({auth}) => auth?.user?.role?.permissions?.permissions ?? [],
    shallowEqual
  ) as ModulePermissionQuery[]

  const hasAccess = useCallback(
    (q?: ModulePermissionQuery) => {
      const code = q ?? pageQuery
      if (!code) return true
      return Boolean(permissions?.find((data) => data === (q ?? pageQuery ?? '')))
    },
    [pageQuery, permissions]
  )

  const canAccessPage = useMemo(() => hasAccess(), [hasAccess])

  const accessRedirect = useCallback(
    (q?: ModulePermissionQuery, path?: string) => {
      if (q) {
        if (canAccessPage && hasAccess(q)) {
          return
        }
        if (path) return history.push(path)
        return history.goBack()
      }
    },
    [canAccessPage, hasAccess, history]
  )
  return {hasAccess, canAccessPage, accessRedirect}
}
