import {
  defaultBreadcrumbs,
  generateBreadcrumb,
} from "src/app/models/screen-prop";

const UserManagementScreens = {
  // ROLE
  USER_MANAGEMENT: {
    PATH: "/user-management",
    TITLE: "User Management",
    TITLE_ALT: "User Management",
    PERMISSION: "user.read",
    breadcrumbs: defaultBreadcrumbs,
  },
  USER_MANAGEMENT_ROLE: {
    PATH: "/user-management/role",
    TITLE: "Role",
    TITLE_ALT: "Role",
    PERMISSION: "roles.read",
    breadcrumbs: defaultBreadcrumbs,
  },
  DETAIL_ROLE: {
    PATH: "/user-management/role/detail/:id",
    TITLE: "Detail Role",
    TITLE_ALT: "Detail Role",
    PERMISSION: "roles.read",
    breadcrumbs: defaultBreadcrumbs,
  },
  EDIT_ROLE: {
    PATH: "/user-management/role/edit/:id",
    TITLE: "Edit Role",
    TITLE_ALT: "Edit Role",
    PERMISSION: "roles.update",
    breadcrumbs: defaultBreadcrumbs,
  },
  ADD_ROLE: {
    PATH: "/user-management/role/add",
    TITLE: "Add Role",
    TITLE_ALT: "Add Role",
    PERMISSION: "roles.create",
    breadcrumbs: defaultBreadcrumbs,
  },
  // EMPLOYEE
  LIST_EMPLOYEE: {
    PATH: "/user-management/employee",
    TITLE: "Karyawan",
    TITLE_ALT: "Karyawan",
    PERMISSION: "karyawan.read",
    breadcrumbs: defaultBreadcrumbs,
  },
  ADD_EMPLOYEE: {
    PATH: "/user-management/employee/add-employee",
    TITLE: "Buat data karyawan",
    TITLE_ALT: "Buat data karyawan",
    PERMISSION: "karyawan.create",
    breadcrumbs: defaultBreadcrumbs,
  },
  EDIT_EMPLOYEE: {
    PATH: "/user-management/employee/edit/:id",
    TITLE: "Edit data karyawan",
    TITLE_ALT: "Edit data karyawan",
    PERMISSION: "karyawan.update",
    breadcrumbs: defaultBreadcrumbs,
  },
  DETAIL_EMPLOYEE: {
    PATH: "/user-management/employee/detail/:id",
    TITLE: "Detail data karyawan",
    TITLE_ALT: "Detail data karyawan",
    PERMISSION: "karyawan.read",
    breadcrumbs: defaultBreadcrumbs,
  },
  MANAGE_MULTIPLE_EMPLOYEES: {
    PATH: "/user-management/employee/manage-multiple-employees",
    TITLE: "Atur Karyawan Sekaligus",
    TITLE_ALT: "Atur Karyawan Sekaligus",
    // PERMISSION: "employee.create",
    breadcrumbs: defaultBreadcrumbs,
  },
  // MEMBERS
  LIST_MEMBERS: {
    PATH: "/user-management/member",
    TITLE: "Member",
    TITLE_ALT: "Member",
    PERMISSION: "member.read",
    breadcrumbs: defaultBreadcrumbs,
  },
  ADD_MEMBERS: {
    PATH: "/user-management/member/add-member",
    TITLE: "Add Member",
    TITLE_ALT: "Add Member",
    PERMISSION: "member.create",
    breadcrumbs: defaultBreadcrumbs,
  },
  EDIT_MEMBERS: {
    PATH: "/user-management/member/edit/:id",
    TITLE: "Edit Member",
    TITLE_ALT: "Edit Member",
    PERMISSION: "member.update",
    breadcrumbs: defaultBreadcrumbs,
  },
  DETAIL_MEMBERS: {
    PATH: "/user-management/member/detail/:id",
    TITLE: "Detail Member",
    TITLE_ALT: "Detail Member",
    PERMISSION: "member.read",
    breadcrumbs: defaultBreadcrumbs,
  },
};

const userBreadcrumbs = generateBreadcrumb([
  UserManagementScreens.USER_MANAGEMENT_ROLE,
]);

UserManagementScreens.ADD_ROLE.breadcrumbs = () => userBreadcrumbs;

export default UserManagementScreens;
