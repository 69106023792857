import {Action} from 'redux'

namespace DashboardRedux {
  export interface ActionWithPayload<T> extends Action {
    payload?: T
  }

  export const actionTypes = {
    DetailDashboar: '[Detail Dashboard] Action',
  }

  export interface IDashboardState {
    data?: any
    isLoading?: boolean
  }

  export const initialState: IDashboardState = {
    data: {},
    isLoading: true,
  }

  export const reducer = (
    state: IDashboardState = initialState,
    action: ActionWithPayload<IDashboardState> = {type: ''}
  ) => {
    if (action.type === actionTypes.DetailDashboar) {
      const data = action.payload?.data
      const isLoading = action.payload?.isLoading

      return {...state, data, isLoading}
    } else {
      return state
    }
  }

  export const actions = {
    detailDashboard: ({data, isLoading}: IDashboardState) => ({
      type: actionTypes.DetailDashboar,
      payload: {data, isLoading},
    }),
  }
}

export default DashboardRedux
