import clsx from 'clsx'
import {ButtonHTMLAttributes, FC} from 'react'
import {Link} from 'react-router-dom'
import GIcon, {GIconProps} from '../Icon/GIcon'

export type ButtonSize = 'small' | 'medium' | 'large'
export type ButtonVariant = 'filled' | 'outline' | 'ghost' | 'unstyled' | 'action'
export type ButtonColor = 'primary' | 'danger' | 'warning' | 'info' | 'success'

export enum ButtonSizes {
  large = 'btn-lg',
  medium = 'btn-md',
  small = 'btn-sm',
}
export enum IconSizes {
  large = 'w-5 h-5',
  medium = 'w-4 h-4',
  small = 'w-[14px] h-[14px]',
}

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  size?: ButtonSize
  variant?: ButtonVariant
  color?: ButtonColor
  loading?: boolean
  actionIcon?: GIconProps
  startIcon?: GIconProps
  endIcon?: GIconProps
  dataTestId?: string
}

export interface GButtonProps extends ButtonProps {
  href?: string
  dataTestId?: string
}

export const getButtonStyleByVariant = (variant: ButtonVariant, color: ButtonColor): string => {
  switch (variant) {
    case 'action':
      return `outline-0 border-0 p-0 w-8 h-8 btn-${variant}-${color} rounded`
    case 'unstyled':
      return `p-0 border-0 bg-transparent outline-0 font-semibold`
    default:
      return `btn min-w-[116px] btn-${variant}-${color}`
  }
}

const Button: FC<ButtonProps> = ({
  className,
  size = 'medium',
  variant = 'filled',
  color = 'primary',
  startIcon,
  endIcon,
  actionIcon,
  children,
  loading,
  dataTestId,
  ...rest
}) => {
  return (
    <button
      className={clsx(getButtonStyleByVariant(variant, color), ButtonSizes[size], className)}
      data-testid={dataTestId ?? 'gbutton-test'}
      {...rest}
    >
      <div className='flex items-center justify-center'>
        {loading ? (
          <GIcon icon='IconLoading' className={`${IconSizes[size]} animate-spin`} />
        ) : (
          <>
            {variant === 'action' ? (
              <>{actionIcon && <GIcon icon={actionIcon} className='w-5 h-5' />}</>
            ) : (
              <>
                {startIcon && <GIcon icon={startIcon} className={`${IconSizes[size]} mr-3`} />}
                {children}
                {endIcon && <GIcon icon={endIcon} className={`${IconSizes[size]} ml-3`} />}
              </>
            )}
          </>
        )}
      </div>
    </button>
  )
}

const GButton: FC<GButtonProps> = ({children, href, ...rest}) => {
  if (href)
    return (
      <Link to={href}>
        <Button {...rest}>{children}</Button>
      </Link>
    )

  return <Button {...rest}>{children}</Button>
}

export default GButton
