/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'

const Footer: FC = () => {
  return (
    <div className='flex flex-col py-4 bg-none'>
      {/* begin::Container */}
      <div className='flex flex-col items-center justify-between px-8 md:flex-row'>
        {/* begin::Copyright */}
        <div className='flex items-center justify-center order-2 text-fs-10 md:order-1 text-neutral-70 hidden'>
          <span className='mr-1'>Powered By</span>
          <a href='https://google.com' className='text-primary me-1'>
            Akarinti Tech
          </a>
          <span className='mr-1'>•</span>
          <span className=''>{new Date().getFullYear()} &copy;</span>
        </div>
        {/* end::Copyright */}
      </div>
      {/* end::Container */}
    </div>
  )
}

export {Footer}
