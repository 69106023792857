import {defaultBreadcrumbs} from 'src/app/models/screen-prop'

const TierAndBenefitScreens = {
  TIER_BENEFIT: {
    PATH: '/tier',
    TITLE: 'Tier & Benefit',
    TITLE_ALT: 'Tier & Benefit',
    breadcrumbs: defaultBreadcrumbs,
  },
  LIST_TIER_MULTIPLIER: {
    PATH: '/tier/multiplier',
    TITLE: 'Tier Multiplier',
    TITLE_ALT: 'Tier Multiplier',
    PERMISSION: 'tier_multiplier.read',
    breadcrumbs: defaultBreadcrumbs,
  },
  EDIT_TIER_MULTIPLIER: {
    PATH: '/tier/multiplier/edit',
    TITLE: 'Edit Tier Multiplier',
    TITLE_ALT: 'Edit Tier Multiplier',
    PERMISSION: 'tier_multiplier.update',
    breadcrumbs: defaultBreadcrumbs,
  },
  LIST_TIER_BENEFIT: {
    PATH: '/tier/benefit',
    TITLE: 'Tier Benefit',
    TITLE_ALT: 'Tier Benefit',
    PERMISSION: 'tier_benefit.read',
    breadcrumbs: defaultBreadcrumbs,
  },
  DETAIL_TIER_BENEFIT: {
    PATH: '/tier/benefit/detail/:id',
    TITLE: 'Detail Tier Benefit',
    TITLE_ALT: 'Detail Tier Benefit',
    PERMISSION: 'tier_benefit.read',
    breadcrumbs: defaultBreadcrumbs,
  },
  LIST_QUALIFICATION_CRITERIA: {
    PATH: '/tier/qualification-criteria',
    TITLE: 'Kriteria Kualifikasi',
    TITLE_ALT: 'Kriteria Kualifikasi',
    PERMISSION: 'kriteria_kualifikasi.read',
    breadcrumbs: defaultBreadcrumbs,
  },
  ADD_QUALIFICATION_CRITERIA: {
    PATH: '/tier/qualification-criteria/add',
    TITLE: 'Tambah Kriteria Kualifikasi',
    TITLE_ALT: 'Tambah Kriteria Kualifikasi',
    PERMISSION: 'kriteria_kualifikasi.create',
    breadcrumbs: defaultBreadcrumbs,
  },
  DETAIL_QUALIFICATION_CRITERIA: {
    PATH: '/tier/qualification-criteria/detail/:id',
    TITLE: 'Detail Kriteria Kualifikasi',
    TITLE_ALT: 'Detail Kriteria Kualifikasi',
    PERMISSION: 'kriteria_kualifikasi.read',
    breadcrumbs: defaultBreadcrumbs,
  },
}

export default TierAndBenefitScreens
