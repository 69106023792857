import axios from "axios";

//new
export const LOGIN_BY_EMAIL = `/admin/v1/login`;

//old
export const CHANGE_PASSWORD = `/api/v1/auth/change-password`;
export const RESET_PASSWORD = `/api/v1/auth/forgot-password`;
export const FORGOT_PASSWORD = `/admin/v1/employees/request-reset-password`;
export const VERIFY_RESET_PASSWORD = `/api/v1/auth/forgot-password-detail`;

interface IForgot {
  email: string;
  type: string;
}

export const loginByEmail = (data?: any, cancelToken?: any) => {
  return axios.post(LOGIN_BY_EMAIL, data, { cancelToken });
};

export const changePassword = (data?: any, config?: any) => {
  return axios.post(CHANGE_PASSWORD, data, config);
};

export const forgotPassword = (body: IForgot) => {
  return axios.post(FORGOT_PASSWORD, body);
};

export const verifyResetPassword = (id: string) => {
  return axios.get(`${VERIFY_RESET_PASSWORD}/${id}`);
};

export const saveResetPassword = (id?: string, data?: any) => {
  return axios.put(`${RESET_PASSWORD}/${id}/new-password`, data, {});
};
