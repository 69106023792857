import {useMemo} from 'react'
import {shallowEqual, useSelector} from 'react-redux'
import {usePermissions} from 'src/app/hooks/permissions-hook'
import AuditTrailScreens from 'src/app/modules/audit-trail/Screens'
import BrandAndOutletScreens from 'src/app/modules/brand-and-outlet/BrandAndOutletScreens'
import BroadcastScreens from 'src/app/modules/broadcast-notification/Screens'
import CampaignAndPromoScreens from 'src/app/modules/campaign-and-points/CampaignAndPromoScreens'
import DashboardScreens from 'src/app/modules/dashboard/Screens'
import ReportScreens from 'src/app/modules/report/Screens'
import ReviewAndFeedbackScreens from 'src/app/modules/review-and-feedback/ReviewAndFeedbackScreens'
import RewardAndVoucherScreens from 'src/app/modules/reward-and-voucher/RewardAndVoucher'
import SettingsScreens from 'src/app/modules/settings/Screens'
import TierAndBenefitScreens from 'src/app/modules/tier-benefit/Screens'
import TransactionScreens from 'src/app/modules/transaction/TransactionScreens'
import UserManagementScreens from 'src/app/modules/user-management/Screens'
import {ModulePermissionQuery} from 'src/app/modules/user-management/models/Role.model'
import {User} from 'src/app/modules/user-management/models/User.model'
import {RootState} from 'src/setup'
import {AsideMenuItem} from './AsideMenuItem'
import {AsideMenuItemWithSub} from './AsideMenuItemWithSub'

interface Menu {
  id?: string
  to?: string
  title: string
  icon?: string
  fontIcon?: string
  hasBullet?: boolean
  children?: Menu[]
  module_permissions?: ModulePermissionQuery
  activePath?: string
  badge?: string
  badgeCircle?: boolean
  hidden?: boolean
}

const useMenus = (user?: any): Menu[] => {
  return useMemo(
    () =>
      [
        {
          title: '',
          children: [
            {
              to: DashboardScreens.DASHBOARD.PATH,
              icon: '/media/icons/IconHomeMakanYa.svg',
              title: DashboardScreens.DASHBOARD.TITLE,
              // module_permissions: DashboardScreens.DASHBOARD.PERMISSION,
            },
            {
              to: UserManagementScreens.USER_MANAGEMENT.PATH,
              icon: '/media/icons/IconUserManagement.svg',
              title: UserManagementScreens.USER_MANAGEMENT.TITLE,
              children: [
                {
                  to: UserManagementScreens.LIST_MEMBERS.PATH,
                  activePath: UserManagementScreens.LIST_MEMBERS.PATH,
                  title: UserManagementScreens.LIST_MEMBERS.TITLE,
                  hasBullet: true,
                  module_permissions: UserManagementScreens.LIST_MEMBERS.PERMISSION,
                },
                {
                  to: UserManagementScreens.LIST_EMPLOYEE.PATH,
                  activePath: UserManagementScreens.LIST_EMPLOYEE.PATH,
                  title: UserManagementScreens.LIST_EMPLOYEE.TITLE,
                  hasBullet: true,
                  module_permissions: UserManagementScreens.LIST_EMPLOYEE.PERMISSION,
                },
                {
                  to: UserManagementScreens.USER_MANAGEMENT_ROLE.PATH,
                  activePath: UserManagementScreens.USER_MANAGEMENT_ROLE.PATH,
                  title: UserManagementScreens.USER_MANAGEMENT_ROLE.TITLE,
                  hasBullet: true,
                  module_permissions: UserManagementScreens.USER_MANAGEMENT_ROLE.PERMISSION,
                },
              ],
            },
            {
              to: BrandAndOutletScreens.BRANDSANDOUTLET.PATH,
              icon: '/media/icons/IconBuy.svg',
              title: BrandAndOutletScreens.BRANDSANDOUTLET.TITLE,
              module_permissions: BrandAndOutletScreens.OUTLET.PERMISSION,
            },
            {
              to: CampaignAndPromoScreens.LOYALTYANDPROMO.PATH,
              icon: '/media/icons/IconCampaign.svg',
              title: CampaignAndPromoScreens.LOYALTYANDPROMO.TITLE,
              children: [
                {
                  to: CampaignAndPromoScreens.CHALLENGE.PATH,
                  activePath: CampaignAndPromoScreens.CHALLENGE.PATH,
                  title: CampaignAndPromoScreens.CHALLENGE.TITLE,
                  hasBullet: true,
                  module_permissions: CampaignAndPromoScreens.CHALLENGE.PERMISSION,
                },
                {
                  to: CampaignAndPromoScreens.EARNING_RATE.PATH,
                  activePath: CampaignAndPromoScreens.EARNING_RATE.PATH,
                  title: CampaignAndPromoScreens.EARNING_RATE.TITLE,
                  hasBullet: true,
                  module_permissions: CampaignAndPromoScreens.EARNING_RATE.PERMISSION,
                },
                {
                  to: CampaignAndPromoScreens.POINT_EXPIRATION.PATH,
                  activePath: CampaignAndPromoScreens.POINT_EXPIRATION.PATH,
                  title: CampaignAndPromoScreens.POINT_EXPIRATION.TITLE,
                  hasBullet: true,
                  module_permissions: CampaignAndPromoScreens.POINT_EXPIRATION.PERMISSION,
                },
              ],
            },
            {
              to: TierAndBenefitScreens.TIER_BENEFIT.PATH,
              icon: '/media/icons/IconList.svg',
              title: TierAndBenefitScreens.TIER_BENEFIT.TITLE,
              children: [
                {
                  to: TierAndBenefitScreens.LIST_QUALIFICATION_CRITERIA.PATH,
                  activePath: TierAndBenefitScreens.LIST_QUALIFICATION_CRITERIA.PATH,
                  title: TierAndBenefitScreens.LIST_QUALIFICATION_CRITERIA.TITLE,
                  hasBullet: true,
                  module_permissions: TierAndBenefitScreens.LIST_QUALIFICATION_CRITERIA.PERMISSION,
                },
                {
                  to: TierAndBenefitScreens.LIST_TIER_MULTIPLIER.PATH,
                  activePath: TierAndBenefitScreens.LIST_TIER_MULTIPLIER.PATH,
                  title: TierAndBenefitScreens.LIST_TIER_MULTIPLIER.TITLE,
                  hasBullet: true,
                  module_permissions: TierAndBenefitScreens.LIST_TIER_MULTIPLIER.PERMISSION,
                },
                {
                  to: TierAndBenefitScreens.LIST_TIER_BENEFIT.PATH,
                  activePath: TierAndBenefitScreens.LIST_TIER_BENEFIT.PATH,
                  title: TierAndBenefitScreens.LIST_TIER_BENEFIT.TITLE,
                  hasBullet: true,
                  module_permissions: TierAndBenefitScreens.LIST_TIER_BENEFIT.PERMISSION,
                },
              ],
            },
            {
              to: RewardAndVoucherScreens.REWARD.PATH,
              icon: '/media/icons/IconVoucher.svg',
              title: RewardAndVoucherScreens.REWARD.TITLE,
              module_permissions: RewardAndVoucherScreens.REWARD.PERMISSION,
            },
            {
              to: TransactionScreens.TRANSACTION_SCREENS.PATH,
              icon: '/media/icons/IconDollar.svg',
              title: TransactionScreens.TRANSACTION_SCREENS.TITLE,
              children: [
                {
                  to: TransactionScreens.PAYMENT_METHOD.PATH,
                  activePath: TransactionScreens.PAYMENT_METHOD.PATH,
                  title: TransactionScreens.PAYMENT_METHOD.TITLE,
                  hasBullet: true,
                  module_permissions: TransactionScreens.PAYMENT_METHOD.PERMISSION,
                },
                {
                  to: TransactionScreens.TOP_UP.PATH,
                  activePath: TransactionScreens.TOP_UP.PATH,
                  title: TransactionScreens.TOP_UP.TITLE,
                  hasBullet: true,
                  module_permissions: TransactionScreens.TOP_UP.PERMISSION,
                },
              ],
            },
            {
              to: BroadcastScreens.LIST_BROADCAST.PATH,
              icon: '/media/icons/IconSend.svg',
              title: BroadcastScreens.LIST_BROADCAST.TITLE,
              module_permissions: BroadcastScreens.LIST_BROADCAST.PERMISSION,
            },
            {
              to: ReviewAndFeedbackScreens.RIVIEWANDFEEDBACK.PATH,
              icon: '/media/icons/IconChatReview.svg',
              title: ReviewAndFeedbackScreens.RIVIEWANDFEEDBACK.TITLE,
              children: [
                {
                  to: ReviewAndFeedbackScreens.FEEDBACKAPPS.PATH,
                  activePath: ReviewAndFeedbackScreens.FEEDBACKAPPS.PATH,
                  title: ReviewAndFeedbackScreens.FEEDBACKAPPS.TITLE,
                  hasBullet: true,
                  module_permissions: ReviewAndFeedbackScreens.FEEDBACKAPPS.PERMISSION,
                },
                {
                  to: ReviewAndFeedbackScreens.RIVIEW.PATH,
                  activePath: ReviewAndFeedbackScreens.RIVIEW.PATH,
                  title: ReviewAndFeedbackScreens.RIVIEW.TITLE,
                  hasBullet: true,
                  module_permissions: ReviewAndFeedbackScreens.RIVIEW.PERMISSION,
                },
              ],
            },
            {
              to: SettingsScreens.SETTINGS.PATH,
              icon: '/media/icons/IconSetting.svg',
              title: SettingsScreens.SETTINGS.TITLE,
              children: [
                {
                  to: SettingsScreens.CATEGORY_FAQ.PATH,
                  activePath: SettingsScreens.CATEGORY_FAQ.PATH,
                  title: SettingsScreens.FAQ.TITLE,
                  hasBullet: true,
                  module_permissions: SettingsScreens.CATEGORY_FAQ.PERMISSION,
                },
                {
                  to: SettingsScreens.BANNER.PATH,
                  activePath: SettingsScreens.BANNER.PATH,
                  title: SettingsScreens.BANNER.TITLE,
                  hasBullet: true,
                  module_permissions: SettingsScreens.BANNER.PERMISSION,
                },
                // {
                //   to: SettingsScreens.ABOUT_US.PATH,
                //   activePath: SettingsScreens.ABOUT_US.PATH,
                //   title: SettingsScreens.ABOUT_US.TITLE,
                //   hasBullet: true,
                //   module_permissions: SettingsScreens.ABOUT_US.PERMISSION,
                // },
                // {
                //   to: SettingsScreens.CONTACT_US.PATH,
                //   activePath: SettingsScreens.CONTACT_US.PATH,
                //   title: SettingsScreens.CONTACT_US.TITLE,
                //   hasBullet: true,
                //   module_permissions: SettingsScreens.CONTACT_US.PERMISSION,
                // },
                // {
                //   to: SettingsScreens.TERMS_AND_CONDITIONS.PATH,
                //   activePath: SettingsScreens.TERMS_AND_CONDITIONS.PATH,
                //   title: SettingsScreens.TERMS_AND_CONDITIONS.TITLE,
                //   hasBullet: true,
                //   module_permissions:
                //     SettingsScreens.TERMS_AND_CONDITIONS.PERMISSION,
                // },
                // {
                //   to: SettingsScreens.TERMS_AND_CONDITIONS_CREDIT.PATH,
                //   activePath: SettingsScreens.TERMS_AND_CONDITIONS_CREDIT.PATH,
                //   title: SettingsScreens.TERMS_AND_CONDITIONS_CREDIT.TITLE,
                //   hasBullet: true,
                //   module_permissions:
                //     SettingsScreens.TERMS_AND_CONDITIONS_CREDIT.PERMISSION,
                // },
                // {
                //   to: SettingsScreens.PRIVACY_POLICY.PATH,
                //   activePath: SettingsScreens.PRIVACY_POLICY.PATH,
                //   title: SettingsScreens.PRIVACY_POLICY.TITLE,
                //   hasBullet: true,
                //   module_permissions: SettingsScreens.PRIVACY_POLICY.PERMISSION,
                // },
              ],
            },
            {
              to: ReportScreens.REPORT.PATH,
              icon: '/media/icons/IconDocument2.svg',
              title: ReportScreens.REPORT.TITLE,
              children: [
                // {
                //   to: ReportScreens.LIST_MEMBERSHIP.PATH,
                //   activePath: ReportScreens.LIST_MEMBERSHIP.PATH,
                //   title: ReportScreens.LIST_MEMBERSHIP.TITLE_ALT,
                //   hasBullet: true,
                //   module_permissions: ReportScreens.LIST_MEMBERSHIP.PERMISSION,
                // },
                // {
                //   to: ReportScreens.LIST_POINT_TRANSACTION.PATH,
                //   activePath: ReportScreens.LIST_POINT_TRANSACTION.PATH,
                //   title: ReportScreens.LIST_POINT_TRANSACTION.TITLE_ALT,
                //   hasBullet: true,
                //   module_permissions: ReportScreens.LIST_POINT_TRANSACTION.PERMISSION,
                // },
                {
                  to: ReportScreens.LIST_REPORT_TOTAL_MEMBER.PATH,
                  activePath: ReportScreens.LIST_REPORT_TOTAL_MEMBER.PATH,
                  title: ReportScreens.LIST_REPORT_TOTAL_MEMBER.TITLE_ALT,
                  hasBullet: true,
                  // module_permissions: ReportScreens.LIST_POINT_TRANSACTION.PERMISSION,
                },
                {
                  to: ReportScreens.LIST_REPORT_TRANSACTION.PATH,
                  activePath: ReportScreens.LIST_REPORT_TRANSACTION.PATH,
                  title: ReportScreens.LIST_REPORT_TRANSACTION.TITLE_ALT,
                  hasBullet: true,
                  // module_permissions: ReportScreens.LIST_POINT_TRANSACTION.PERMISSION,
                },
                {
                  to: ReportScreens.LIST_REPORT_VOUCHER_PERFORMANCE.PATH,
                  activePath: ReportScreens.LIST_REPORT_VOUCHER_PERFORMANCE.PATH,
                  title: ReportScreens.LIST_REPORT_VOUCHER_PERFORMANCE.TITLE_ALT,
                  hasBullet: true,
                  // module_permissions: ReportScreens.LIST_POINT_TRANSACTION.PERMISSION,
                },
                {
                  to: ReportScreens.LIST_REPORT_CHALLENGE_PERFORMANCE.PATH,
                  activePath: ReportScreens.LIST_REPORT_CHALLENGE_PERFORMANCE.PATH,
                  title: ReportScreens.LIST_REPORT_CHALLENGE_PERFORMANCE.TITLE_ALT,
                  hasBullet: true,
                  // module_permissions: ReportScreens.LIST_POINT_TRANSACTION.PERMISSION,
                },
              ],
            },
            {
              to: AuditTrailScreens.ADD_AUDIT_TRAIL.PATH,
              icon: '/media/icons/IconAuditTrail.svg',
              title: AuditTrailScreens.ADD_AUDIT_TRAIL.TITLE,
              module_permissions: AuditTrailScreens.AUDIT_TRAIL.PERMISSION,
            },
          ],
        },
      ] as Menu[],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [user]
  )
}

const GeneratedMenu: React.FC<{menu: Menu}> = ({menu}) => {
  if (!menu.to) {
    if (!menu.children || menu.children.length === 0) return null
    return (
      <>
        <div className='block p-0'>
          <div className='px-6 pt-8 pb-2'>
            <span className='tracking-widest text-white uppercase text-fs-10'>{menu.title}</span>
          </div>
        </div>
        {menu.children?.map((child) => {
          return <GeneratedMenu menu={child} key={child.id} />
        })}
      </>
    )
  }
  if (!menu.children)
    return (
      <AsideMenuItem
        to={menu.to}
        icon={menu.icon}
        title={menu.title}
        fontIcon={menu.fontIcon}
        hasBullet={menu.hasBullet}
        activePath={menu.activePath}
        badge={menu.badge}
        badgeCircle={menu.badgeCircle}
      />
    )

  return (
    <AsideMenuItemWithSub
      to={menu.to}
      title={menu.title}
      fontIcon={menu.fontIcon}
      icon={menu.icon}
      activePath={menu.activePath}
    >
      {menu.children.map((child) => {
        return <GeneratedMenu menu={child} key={child.id} />
      })}
    </AsideMenuItemWithSub>
  )
}

const filterMenus = (
  menus: Menu[] | undefined,
  predicate: (menu: Menu) => boolean
): Menu[] | undefined => {
  const result = menus?.map((menu, index) => ({
    ...menu,
    id: String(index),
    children: filterMenus(menu.children, predicate),
  }))
  return result?.filter((menu) => (!menu.children || menu.children.length > 0) && predicate(menu))
}

const AsideMenuData: React.FC = () => {
  const user: User = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as User
  const menus = useMenus(user)
  const {hasAccess} = usePermissions()

  const generated = useMemo(
    () =>
      filterMenus(menus, (menu) => hasAccess(menu?.module_permissions) && !Boolean(menu.hidden)),

    [menus, hasAccess]
  )

  return (
    <>
      {generated?.map((child) => {
        return <GeneratedMenu menu={child} key={child.id} />
      })}
    </>
  )
}

export default AsideMenuData
