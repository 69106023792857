// import clsx from 'clsx'
import {FC, HTMLProps, useEffect, useState} from 'react'
import Select, {SelectComponentsConfig} from 'react-select'
import {AsyncPaginate} from 'react-select-async-paginate'
import {CustomReactSelect} from '../Custom/CustomReactSelect'

export interface SelectOption {
  label?: string | null
  value?: string | number | null
}

export interface FormSelectProps extends Omit<HTMLProps<HTMLInputElement>, 'defaultValue'> {
  error?: string
  touched?: boolean
  options?: SelectOption[]
  defaultValue?: SelectOption
  className?: string
  changedValue?: (v: SelectOption) => void
  disabled?: boolean
  selectedValue?: string | number | null
  asyncSelect?: boolean
  loadOptions?: any
  isLoading?: boolean
  isClearable?: boolean
  cacheUniqs?: any[]
}

const FormSelect: FC<FormSelectProps> = ({
  options,
  placeholder,
  error,
  touched,
  defaultValue,
  className,
  changedValue,
  disabled,
  selectedValue,
  asyncSelect,
  loadOptions,
  isLoading,
  isClearable,
  cacheUniqs,
}) => {
  const [val, setVal] = useState<SelectOption | SelectOption[] | ''>('')

  const onChange = (v: SelectOption) => {
    if (changedValue) {
      if (v !== null) {
        changedValue(v)
        setVal(v)
      }
    }
  }
  useEffect(() => {
    if (!asyncSelect) {
      if (selectedValue === '' || selectedValue === undefined) {
        setVal('')
      } else {
        const payload = options?.find((pay) => pay.value === selectedValue)
        setVal({value: selectedValue, label: payload?.label})
      }
    }
  }, [selectedValue, options, asyncSelect])

  useEffect(() => {
    if (defaultValue) {
      setVal(defaultValue)
    }
  }, [defaultValue])

  useEffect(() => {
    if (asyncSelect && defaultValue) {
      setVal({value: defaultValue?.value, label: defaultValue?.label})
    }
  }, [defaultValue, asyncSelect])

  return (
    <div data-testid='formselect-test' className={className}>
      {asyncSelect ? (
        <AsyncPaginate
          data-testid='formselect-test-value'
          // cacheOptions
          defaultOptions
          placeholder={placeholder}
          onChange={onChange}
          loadOptions={loadOptions}
          cacheUniqs={cacheUniqs}
          debounceTimeout={500}
          components={CustomReactSelect as SelectComponentsConfig<any, false, any>}
          value={val}
          defaultValue={defaultValue}
          isDisabled={disabled}
          styles={{
            option: (base, state) => ({
              ...base,
              backgroundColor: state.isFocused ? '#EFF6FF' : '',
            }),
          }}
          //tailwind classname react-select v5
          classNames={{
            placeholder: (state) => 'text-neutral-60 font-medium capitalize',
            control: () =>
              `text-fs-9 rounded-lg font-medium text-neutral-100 ${
                touched && error ? 'border-danger' : 'border-none'
              } ${disabled && 'bg-neutral-40'}`,
            valueContainer: (state) => `min-h-0 px-4 ${state.isMulti ? 'py-0 h-11' : 'py-3'}`,
            input: () => 'm-0 p-0',
            dropdownIndicator: (state) =>
              `transition-transform duration-150 ${
                state.selectProps.menuIsOpen ? 'rotate-180' : 'rotate-0'
              }`,
            indicatorsContainer: () => 'max-h-[44px]',
            option: (state) =>
              `text-fs-9 rounded px-2 py-3 cursor-pointer ${
                state.isFocused ? 'text-danger' : 'text-neutral-80'
              }`,
            menuList: () => 'p-3 border-none max-h-[300px]',
            menu: () => 'shadow-none drop-shadow-[0_0_50px_rgba(33,37,41,0.13)]',
          }}
          isSearchable
          isClearable={isClearable}
          isLoading={isLoading}
        />
      ) : (
        <Select
          data-testid='formselect-test-value'
          components={CustomReactSelect as SelectComponentsConfig<any, false, any>}
          options={options}
          onChange={onChange}
          placeholder={placeholder}
          defaultValue={defaultValue}
          value={val}
          isDisabled={disabled}
          styles={{
            option: (base, state) => ({
              ...base,
              backgroundColor: state.isFocused ? '#EFF6FF' : '',
            }),
          }}
          //tailwind classname react-select v5
          classNames={{
            placeholder: (state) => 'text-neutral-60 font-medium capitalize',
            control: () =>
              `text-fs-9 rounded-lg font-medium text-neutral-100 ${
                touched && error ? 'border-danger' : 'border-none'
              } ${disabled && 'bg-neutral-40'}`,
            valueContainer: (state) => `min-h-0 px-4 ${state.isMulti ? 'py-0 h-11' : 'py-3'}`,
            input: () => 'm-0 p-0',
            dropdownIndicator: (state) =>
              `transition-transform duration-150 ${
                state.selectProps.menuIsOpen ? 'rotate-180' : 'rotate-0'
              }`,
            indicatorsContainer: () => 'max-h-[44px]',
            option: (state) =>
              `text-fs-9 rounded px-2 py-3 cursor-pointer ${
                state.isFocused ? 'text-danger' : 'text-neutral-80'
              }`,
            menuList: () => 'p-3 border-none max-h-[300px]',
            menu: () => 'shadow-none drop-shadow-[0_0_50px_rgba(33,37,41,0.13)]',
          }}
        />
      )}

      {touched && error && <div className='mt-2 font-medium text-danger text-fs-10'>{error}</div>}
    </div>
  )
}

export {FormSelect}
