import {defaultBreadcrumbs} from 'src/app/models/screen-prop'

const RewardAndVoucherScreens = {
  REWARDANDVOUCHER: {
    PATH: '/reward-and-voucher',
    TITLE: 'Reward & Voucher',
    TITLE_ALT: 'Reward & Voucher',
    // PERMISSION: "category.read",
    breadcrumbs: defaultBreadcrumbs,
  },
  REWARD: {
    PATH: '/reward-and-voucher/reward',
    TITLE: 'Reward & Voucher',
    TITLE_ALT: 'Reward & Voucher',
    PERMISSION: 'reward_voucher.read',
    breadcrumbs: defaultBreadcrumbs,
  },
  REWARD_ADD: {
    PATH: '/reward-and-voucher/reward/add',
    TITLE: 'Add Reward',
    TITLE_ALT: 'Add Reward',
    PERMISSION: 'reward_voucher.create',
    breadcrumbs: defaultBreadcrumbs,
  },
  REWARD_EDIT: {
    PATH: '/reward-and-voucher/reward/edit/:id',
    TITLE: 'Edit Reward',
    TITLE_ALT: 'Edit Reward',
    PERMISSION: 'reward_voucher.update',
    breadcrumbs: defaultBreadcrumbs,
  },
  REWARD_DETAIL: {
    PATH: '/reward-and-voucher/reward/detail/:id',
    TITLE: 'Detail Reward',
    TITLE_ALT: 'Detail Reward',
    PERMISSION: 'reward_voucher.read',
    breadcrumbs: defaultBreadcrumbs,
  },
}

export default RewardAndVoucherScreens
