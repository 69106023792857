import clsx from "clsx";
import React, { useCallback, useEffect } from "react";
import { useMasterLayout } from "src/app/context/MasterContext";
import { PageToasts, useHeaderToast } from "src/app/context/ToastContext";
import GIcon, { GIconProps } from "../Icon/GIcon";

interface GToastProps {
  data: PageToasts;
  removePageToasts: (toast: PageToasts) => void;
  delay?: number;
  autohide?: boolean;
}

export const GToast: React.FC<GToastProps> = ({
  data,
  removePageToasts,
  delay = 5000,
  autohide,
}) => {
  useEffect(() => {
    let timeout: ReturnType<typeof setTimeout>;

    if (autohide) {
      timeout = setTimeout(() => {
        removePageToasts(data);
      }, delay);
    }

    return () => timeout && clearTimeout(timeout);
    //eslint-disable-next-line
  }, [autohide, delay, data]);

  const iconSchemeFn = (data: PageToasts): GIconProps | undefined => {
    if (data.scheme === "success") return "IconCheckCircle";
    if (data.scheme === "danger") return "IconExclamation";
  };

  return (
    <div
      className={clsx(
        "transition-opacity w-full block text-fs-9 rounded-lg mb-4 opacity-0 duration-500 border",
        { "opacity-100": data.shown },
        {
          "bg-danger-light-2 text-danger-dark-2 border-danger-light-1":
            data.scheme === "danger",
        },
        {
          "bg-info-light-2 text-info-dark-2 border-info-light-1":
            data.scheme === "info",
        },
        {
          "bg-success-light-2 text-success-dark-2 border-success-light-1":
            data.scheme === "success",
        },
        {
          "bg-warning-light-2 text-warning-dark-2 border-warning-light-1":
            data.scheme === "warning",
        },
        {
          "bg-primary-light-2 text-primary-dark-2 border-primary-light-1":
            data.scheme === "primary",
        }
      )}
    >
      <div className="flex items-center justify-center p-4 break-words">
        <GIcon
          className="w-5 h-5 mr-3"
          icon={iconSchemeFn(data) as GIconProps}
        />

        <span className="flex-1 font-medium">{data.text}</span>

        <GIcon
          onClick={() => removePageToasts(data)}
          className="w-5 h-5 cursor-pointer"
          icon="IconClose"
          data-testid="close-button"
        />
      </div>
    </div>
  );
};

export const HeaderToast: React.FC<{ auth?: boolean }> = ({ auth }) => {
  const { removePageToasts, hookPageToasts } = useHeaderToast();
  const { minimize } = useMasterLayout();

  const pageToasts = hookPageToasts(useCallback((data) => data, []));

  return (
    <div
      className={clsx(
        `z-[9999] fixed right-0 left-0 ${
          auth ? "top-9 md:w-[660px]" : "top-28"
        }`,
        { "lg:left-[384px]": auth },
        { "lg:left-[72px]": minimize },
        { "lg:left-[264px]": !minimize }
      )}
    >
      <div className="flex justify-center w-full px-8 mx-auto">
        {pageToasts?.map((data) => (
          <GToast
            data={data}
            removePageToasts={removePageToasts}
            key={data.timestamp}
            autohide
            delay={3000}
          />
        ))}
      </div>
    </div>
  );
};
