import clsx from 'clsx'
import React, {useEffect} from 'react'

const GuestWrapper: React.FC<{
  withTitle?: boolean
  children?: React.ReactNode
}> = ({withTitle = true, children}) => {
  useEffect(() => {
    document.body.classList.add('bg-light')
    return () => document.body.classList.remove('bg-light')
  }, [])

  return (
    <div
      className={clsx(
        "h-screen w-screen flex flex-col bg-neutral-10 bg-[url('/public/media/backgrounds/bg-auth.png')] bg-cover bg-no-repeat bg-bottom",
        'lg:flex-row'
      )}
      data-testid='content-wrapper-component'
    >
      <div className='flex items-center justify-center flex-1 p-16 lg:p-20'>
        <div className='flex items-center justify-center w-[504px]  p-12 rounded-lg card'>
          <div className='w-[356px]'>{children}</div>
        </div>
      </div>
    </div>
  )
}

export default GuestWrapper
