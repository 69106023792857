import axios, {CancelToken} from 'axios'
import {BaseResponse, Pagination, QueryModel, Response} from 'src/app/models/api.types'
import {IEmployee, QueryEmployee} from '../models/Employee.model'
import {IMember, IResendSetPassword, QueryMember} from '../models/Member.model'
import {Role, RoleAccessTemplate, RoleDetail} from '../models/Role.model'
import {User} from '../models/User.model'
import {
  EMPLOYEE,
  EMPLOYEE_BULK,
  EMPLOYEE_DOWNLOAD_TEMPLATE,
  MEMBER,
  PATH_PROVINCE,
  PROFILE,
  RESEND_SET_PASSWORD,
  RESET_PASSWORD,
  RESET_PASSWORD_EMPLOYEE,
  ROLE,
  ROLE_ACCESS,
  USER,
  USER_ROLE,
} from './PathServices'

export const getListUser = (params?: QueryModel, cancelToken?: CancelToken) => {
  return axios.get<BaseResponse<Pagination<User[]>>>(USER, {
    params,
    cancelToken,
  })
}

export const getDetailUser = (id?: string, cancelToken?: CancelToken) => {
  return axios.get<BaseResponse<Response<User>>>(`${USER}/${id}`, {
    cancelToken,
  })
}

export const getListProvince = (params?: any) => {
  return axios.get<BaseResponse<Pagination<any>>>(`${PATH_PROVINCE}`, {params})
}

export const getListCity = (id: any) => {
  return axios.get<BaseResponse<Response<any>>>(`${PATH_PROVINCE}/${id}/cities`)
}

export const getListProvinceAndCities = () => {
  return axios.get<BaseResponse<Response<any>>>(`${PATH_PROVINCE}${'?include=cities'}`)
}

export const saveUser = (data?: User, id?: string, cancelToken?: CancelToken) => {
  if (id)
    return axios.put(`${USER}/${id}`, data, {
      cancelToken,
    })

  return axios.post(USER, data, {
    cancelToken,
  })
}

export const deleteUser = (id?: string, data?: any, cancelToken?: CancelToken) => {
  return axios.delete(`${USER}/${id}`, {
    cancelToken,
    data,
  })
}

export const verificationUserToken = (data?: any, cancelToken?: CancelToken) => {
  return axios.post(`${USER}/verification-token`, data, {
    cancelToken,
  })
}

export const resetPasswordUser = (data?: any) => {
  return axios.post(`${RESET_PASSWORD}`, data)
}

export const resetPasswordEmployee = (data?: any) => {
  return axios.post(`${RESET_PASSWORD_EMPLOYEE}`, data)
}

export const getProfile = (cancelToken?: CancelToken) => {
  return axios.get<BaseResponse<Response<User>>>(`${PROFILE}`, {
    cancelToken,
  })
}

export const getListRole = (params?: QueryModel, cancelToken?: CancelToken) => {
  return axios.get<BaseResponse<Pagination<Role[]>>>(ROLE, {
    params,
    cancelToken,
  })
}

export const getDetailRole = (id?: string, cancelToken?: CancelToken) => {
  return axios.get<BaseResponse<Response<RoleDetail>>>(`${ROLE}/${id}`, {
    cancelToken,
  })
}

export const saveRole = (data?: Role, id?: string, cancelToken?: CancelToken) => {
  if (id)
    return axios.put(`${ROLE}/${id}`, data, {
      cancelToken,
    })

  return axios.post(ROLE, data, {
    cancelToken,
  })
}

export const deleteRole = (id?: string, cancelToken?: CancelToken) => {
  return axios.delete(`${ROLE}/${id}`, {
    cancelToken,
  })
}

export const getListAccessTemplateRole = (cancelToken?: CancelToken) => {
  return axios.get<BaseResponse<Response<RoleAccessTemplate[]>>>(ROLE_ACCESS, {
    cancelToken,
  })
}

// Employee
export const getListEmployee = (params?: QueryEmployee, cancelToken?: CancelToken) => {
  return axios.get<BaseResponse<Pagination<IEmployee[]>>>(EMPLOYEE, {
    params,
    cancelToken,
  })
}

export const saveEmployee = (data?: IEmployee, id?: string, cancelToken?: CancelToken) => {
  if (id)
    return axios.put(`${EMPLOYEE}/${id}`, data, {
      cancelToken,
    })

  return axios.post(EMPLOYEE, data, {
    cancelToken,
  })
}

export const getDetailEmployee = (id?: string) => {
  return axios.get<BaseResponse<Response<IEmployee>>>(`${EMPLOYEE}/${id}`)
}

export const getListAccessAdmin = (params?: any) => {
  return axios.get<BaseResponse<Response<any>>>(`${EMPLOYEE}/verification-status`, {params})
}

export const getUserRolesList = (params?: any) => {
  return axios.get<BaseResponse<Response<any>>>(USER_ROLE, {params})
}

export const deleteEmployee = (id?: string) => {
  return axios.delete(`${EMPLOYEE}/${id}`)
}

// Member
export const getListMember = (params?: QueryMember) => {
  return axios.get<BaseResponse<Pagination<IMember[]>>>(MEMBER, {
    params,
  })
}

export const saveMember = (data?: IMember, id?: string) => {
  if (id) return axios.put(`${MEMBER}/${id}`, data)

  return axios.post(MEMBER, data)
}

export const getDetailMember = (id?: string) => {
  return axios.get<BaseResponse<Response<IMember>>>(`${MEMBER}/${id}`)
}

export const deleteMember = (id?: string) => {
  return axios.delete(`${MEMBER}/${id}`)
}

export const resendSetPassword = (body: IResendSetPassword) => {
  return axios.post(RESEND_SET_PASSWORD, body)
}

export const employeeBulk = (data?: any) => {
  return axios.post(EMPLOYEE_BULK, data)
}

export const downloadTemplate = () => {
  return axios.get(EMPLOYEE_DOWNLOAD_TEMPLATE)
}
