/* eslint-disable array-callback-return */
import {useFormik} from 'formik'
import {FC, useState} from 'react'
import {useHistory} from 'react-router-dom'
import GAlert from 'src/app/components/Libs/Alert/GAlert'
import GButton from 'src/app/components/Libs/Button/GButton'
import {FormLabel, FormText} from 'src/app/components/Libs/Form/Collection'
import GIcon from 'src/app/components/Libs/Icon/GIcon'
import ConfirmModal from 'src/app/components/Libs/Modal/Collection/ConfirmModal'
import * as Yup from 'yup'
import AuthScreens from '../../Screens'
import {IStateShowConfirm} from '../../models/Auth.model'
import {forgotPassword} from '../../services/Auth.services'

const ForgotPassword: FC = () => {
  const [loading, setLoading] = useState(false)
  const [isModalResponse, setIsModalResponse] = useState<IStateShowConfirm>({
    isShow: false,
    status: '',
    title: '',
    subtitle: '',
  })

  const history = useHistory()

  const loginSchema = Yup.object().shape({
    email: Yup.string()
      .email('Format email tidak sesuai')
      .required('Email wajib diisi')
      .matches(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/, 'Format email tidak sesuai'),
  })

  const initialValues = {
    email: '',
  }

  const formik = useFormik({
    initialValues,
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: loginSchema,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      setTimeout(async () => {
        try {
          const body = {
            email: values.email,
            type: 'reset-password',
          }
          const response = await forgotPassword(body)
          if (response.status === 201 || response.status === 200) {
            setLoading(false)
            setSubmitting(false)
            setIsModalResponse({
              ...isModalResponse,
              isShow: true,
              status: 'success',
              title: 'Email verifikasi berhasil dikirim',
              subtitle:
                'Periksa kotak masuk Anda untuk menemukan tautan verifikasi email guna mengatur ulang password Anda.',
            })
          }
        } catch (e: any) {
          const response = e.response.data?.response_schema
          const status = e.response.status
          if (status >= 500) {
            setStatus('Layanan sementara tidak tersedia. Coba lagi nanti.')
          } else {
            setStatus(response?.response_message)
          }
          setLoading(false)
          setSubmitting(false)
        }
      }, 1000)
    },
  })

  return (
    <>
      <div className='w-full' data-testid='forgot-password-page'>
        <div className='mb-5'>
          <GIcon icon='IconLeftRow' className='w-4 h-4 cursor-pointer' onClick={history.goBack} />
        </div>
        <>
          <div className='mb-12 text-center'>
            <div className='mb-4 font-bold text-fs-5'>Lupa Password</div>
            <div className='text-neutral-80'>
              Mengatur ulang kata sandi dengan meminta tautan yang dikirim ke email terdaftar.
            </div>
          </div>

          {formik.status && <GAlert className='mb-8'>{formik.status}</GAlert>}

          <form className='w-full' onSubmit={formik.handleSubmit} noValidate>
            <div className='mb-8'>
              <FormLabel required className='mb-2'>
                Email
              </FormLabel>
              <FormText
                {...formik.getFieldProps('email')}
                name='email'
                type='email'
                placeholder='Harap masukkan email Anda'
                maxLength={50}
                error={formik.errors.email}
                touched={formik.touched.email}
              />
            </div>

            <div className='flex items-center justify-between gap-4'>
              <GButton
                type='submit'
                size='large'
                className='flex-1 bg-danger border-0'
                loading={loading}
                disabled={loading}
              >
                Kirim Link
              </GButton>
            </div>
          </form>
        </>
      </div>
      <ConfirmModal
        size='sm'
        handleClose={() => {}}
        show={isModalResponse.isShow}
        nameIcons={isModalResponse.status}
      >
        <div className='mb-3 flex justify-center'>
          <p className=' font-bold text-sm text-[#020617]'>{isModalResponse.title}</p>
        </div>
        <div className='mb-3 flex justify-center text-center'>
          <p className=' text-sm'>{isModalResponse.subtitle}</p>
        </div>
        <div className='mt-5'>
          <GButton
            href={AuthScreens.LOGIN_EMAIL.PATH}
            className=' w-full bg-white border border-[#E11D48] text-[#E11D48]'
          >
            Kembali ke Halaman Login
          </GButton>
        </div>
      </ConfirmModal>
    </>
  )
}

export default ForgotPassword
