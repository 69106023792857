import { FC, MouseEventHandler, ReactNode } from "react";
import GModal from "../GModal";
import ModalFooter from "../ModalFooter";
import ModalHeader from "../ModalHeader";

interface BasicModalProps {
  show: boolean;
  handleSuccess: MouseEventHandler;
  handleClose: MouseEventHandler;
  header: string;
  children?: ReactNode;
  positiveLabel?: string;
  negativeLabel?: string;
  size?: "xl" | "lg" | "md" | "sm";
  loading?: boolean;
  isShowFooter?: boolean;
}

const BasicModal: FC<BasicModalProps> = ({
  show,
  handleSuccess,
  handleClose,
  header,
  positiveLabel,
  negativeLabel,
  size = "md",
  loading,
  children,
  isShowFooter,
}) => {
  return (
    <GModal show={show} onHide={handleClose} size={size}>
      <ModalHeader header={header} handleClose={handleClose} />
      <div className="px-6 py-5 min-h-[100px] text-neutral-80">{children}</div>
      {!isShowFooter && (
        <ModalFooter
          handleClose={handleClose}
          handleSuccess={handleSuccess}
          positiveLabel={positiveLabel}
          negativeLabel={negativeLabel}
          loading={loading}
        />
      )}
    </GModal>
  );
};

export default BasicModal;
