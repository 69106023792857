import clsx from 'clsx'
import React from 'react'
import {useLocation} from 'react-router'
import {Link} from 'react-router-dom'
import {useMasterLayout} from 'src/app/context/MasterContext'
import {checkIsActive} from 'src/app/utils/router-helpers-utils'
import {KTSVG} from '../../Libs/Icon/KTSVG'

type Props = {
  children?: React.ReactNode
  to: string
  title: string
  icon?: string
  fontIcon?: string
  hasBullet?: boolean
  activePath?: string
  badge?: string
  badgeCircle?: boolean
}

const AsideMenuItem: React.FC<Props> = ({
  children,
  to,
  title,
  icon,
  fontIcon,
  hasBullet = false,
  activePath,
  badge,
  badgeCircle,
}) => {
  const {pathname} = useLocation()
  const isActive = checkIsActive(pathname, activePath ?? to)
  const {minimize} = useMasterLayout()

  return (
    <div className='block p-0'>
      <Link
        className={clsx(
          'p-3 mx-3 cursor-pointer flex flex-nowrap truncate items-center rounded-lg transition duration-100',
          'hover:text-primary-light-2',
          {
            'bg-darkMode-dark-1 text-primary-light-2 dark:bg-inverse-primary-light-3': isActive,
          },
          {'text-neutral-50': !isActive}
        )}
        to={to}
      >
        {hasBullet && (
          <span className='flex items-center justify-center w-6 mr-2 shrink-0'>
            <span
              className={clsx('w-1 h-1 rounded-full', 'hover:bg-primary-light-2', {
                'bg-primary-light-2': isActive,
                'bg-neutral-50': !isActive,
              })}
            ></span>
          </span>
        )}
        {icon && (
          <span className='flex items-center w-8 shrink-0'>
            <KTSVG path={icon} className='w-6 h-6' />
          </span>
        )}
        {/* still bootstrap style / change it !  */}
        {fontIcon && <i className={clsx('bi fs-3', fontIcon)}></i>}

        <div
          className={clsx('flex items-center transition duration-300 whitespace-normal ', {
            'opacity-0 group-hover:opacity-100': minimize,
          })}
        >
          {title}
        </div>

        {/* still bootstrap style / change it !  */}
        {badge ? (
          <span
            className={clsx('badge badge-light-secondary', {
              'badge-circle': badgeCircle,
            })}
            style={{minWidth: 23, minHeight: 23}}
          >
            {badge}
          </span>
        ) : null}
      </Link>
      {children}
    </div>
  )
}

export {AsideMenuItem}
