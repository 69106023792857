import clsx from 'clsx'
import {FC} from 'react'
import {Link} from 'react-router-dom'
import {usePageData} from 'src/app/context/PageDataContext'

const trimBreadcrumbs = (text?: string) => {
  if (!text) return text
  if (text.length > 80) return `${text.substring(0, 38)}…`
  return text
}

const GBreadcrumbs: FC = () => {
  const {pageTitle, pageDescription, pageBreadcrumbs} = usePageData()

  return (
    <div>
      {(pageDescription || (pageBreadcrumbs && pageBreadcrumbs.length > 0)) && (
        <>
          <ul className='flex flex-wrap items-center p-0 m-0 list-none text-fs-10'>
            {Array.from(pageBreadcrumbs ?? []).map((item) => (
              <li
                className={clsx('flex items-center pr-2', {
                  'text-neutral-80 dark:text-neutral-30': !item.isSeparator && item.isActive,
                  'text-neutral-70 dark:text-neutral-20': !item.isSeparator && !item.isActive,
                })}
                key={`${item.path}`}
              >
                {!item.isSeparator ? (
                  <Link
                    className='transition-all duration-200 text-neutral-70 hover:text-neutral-80'
                    to={item.path}
                  >
                    {trimBreadcrumbs(item.title)}
                  </Link>
                ) : (
                  <span className='text-neutral-70'>-</span>
                )}
              </li>
            ))}
            <li className='flex items-center pr-2 text-neutral-80 dark:text-neutral-20'>
              {trimBreadcrumbs(pageDescription ? pageDescription : pageTitle)}
            </li>
          </ul>
        </>
      )}
    </div>
  )
}

export default GBreadcrumbs
