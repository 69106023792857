import clsx from 'clsx'
import {FC, ReactNode} from 'react'

export interface FormLabelProps {
  children?: string
  required?: boolean
  optional?: boolean
  description?: string | ReactNode
  className?: string
  appendIcon?: any
}

const FormLabel: FC<FormLabelProps> = ({
  children,
  required,
  optional,
  description,
  className = '',
  appendIcon,
}) => {
  return (
    <div className={clsx('flex flex-col', className)} data-testid='form-label-container'>
      <label className='flex font-medium text-fs-9 text-neutral-80 capitalize'>
        {children}
        {required && <span className='ml-1 text-danger'>*</span>}
        {appendIcon}
        {optional && <span className='ml-1 text-neutral-70'>(Opsional)</span>}
      </label>

      {description && (
        <div className='flex items-center mt-1'>
          <label className='text-fs-10 text-neutral-70'>{description}</label>
        </div>
      )}
    </div>
  )
}

export {FormLabel}
