const ReportScreens = {
  REPORT: {
    PATH: '/report',
    TITLE: 'Report',
    TITLE_ALT: 'Report',
    // PERMISSION: 'broadcast.read',
  },
  LIST_POINT_TRANSACTION: {
    PATH: '/report/point-transaction/list',
    TITLE: 'Daftar Point Transaction',
    TITLE_ALT: 'Point Transaction',
    PERMISSION: 'point_transaksi.read',
  },
  DETAIL_POINT_TRANSACTION: {
    PATH: '/report/point-transaction/list/detail/:id',
    TITLE: 'Detail Report',
    TITLE_ALT: 'Detail Report',
    PERMISSION: 'point_transaksi.read',
  },
  LIST_MEMBERSHIP: {
    PATH: '/report/membership/list',
    TITLE: 'Daftar Membership',
    TITLE_ALT: 'Membership',
    PERMISSION: 'membership.read',
  },
  LIST_REPORT_TOTAL_MEMBER: {
    PATH: '/report/report-total-member/list',
    TITLE: 'Membership Tier Distribution',
    TITLE_ALT: 'Membership Tier Distribution',
    // PERMISSION: 'membership.read',
  },
  LIST_REPORT_NEW_MEMBER: {
    PATH: '/report/new-member/list',
    TITLE: 'Report Member ',
    TITLE_ALT: 'Member',
    // PERMISSION: 'membership.read',
  },
  LIST_REPORT_TRANSACTION: {
    PATH: '/report/transaction/list',
    TITLE: 'Report Transaksi ',
    TITLE_ALT: 'Transaksi',
    // PERMISSION: 'membership.read',
  },
  LIST_REPORT_VOUCHER_PERFORMANCE: {
    PATH: '/report/voucher-performance/list',
    TITLE: 'Report Voucher Performance ',
    TITLE_ALT: 'Voucher Performance',
    // PERMISSION: 'membership.read',
  },
  LIST_REPORT_CHALLENGE_PERFORMANCE: {
    PATH: '/report/challenge-performance/list',
    TITLE: 'Report Challenge Performance',
    TITLE_ALT: 'Challenge Performance',
    // PERMISSION: 'membership.read',
  },
}

export default ReportScreens
