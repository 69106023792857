import clsx from 'clsx'
import {FC, MouseEventHandler, ReactNode} from 'react'
import GIcon from '../../Icon/GIcon'
import GModal from '../GModal'

interface IConfirmModal {
  show?: boolean
  handleClose: MouseEventHandler
  children?: ReactNode
  size?: 'xl' | 'lg' | 'md' | 'sm'
  nameIcons?: string
}

const ConfirmModal: FC<IConfirmModal> = ({show, handleClose, size = 'md', children, nameIcons}) => {
  return (
    <GModal show={show} onHide={handleClose} size={size}>
      <div className='p-5' data-testid='confirm-modal'>
        <div className='flex justify-center'>
          <GIcon
            icon={nameIcons === 'success' ? 'IconChecklist' : 'IconWarningDanger'}
            className={clsx('cursor-pointer w-16 h-16')}
          />
        </div>
      </div>
      <div className='p-5'>{children}</div>
    </GModal>
  )
}

export default ConfirmModal
