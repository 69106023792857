import clsx from 'clsx'
import React, {useEffect, useRef} from 'react'
import {useLocation} from 'react-router'
import {AsideMenuMain} from './AsideMenuMain'

const AsideMenu: React.FC = () => {
  const scrollRef = useRef<HTMLDivElement | null>(null)
  const {pathname} = useLocation()

  useEffect(() => {
    setTimeout(() => {
      if (scrollRef.current) {
        scrollRef.current.scrollTop = 0
      }
    }, 50)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname])

  return (
    <div
      data-testid='aside-menu-data'
      ref={scrollRef}
      className='mt-5 mb-20 overflow-y-scroll no-scrollbar'
    >
      <div className={clsx('')}>
        <AsideMenuMain />
      </div>
    </div>
  )
}

export {AsideMenu}
