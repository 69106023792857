export const USER = '/api/v1/admins/users'
export const ROLE = '/admin/v1/user-roles'
export const EMPLOYEE = '/admin/v1/employees'
export const MEMBER = '/admin/v1/members'
export const ROLE_ACCESS = '/admin/v1/user-roles/module-permissions'
export const RESET_PASSWORD = '/admin/v1/employees/reset-password'
export const RESET_PASSWORD_EMPLOYEE = '/admin/v1/employees/set-password'
export const PROFILE = '/admin/v1/employees/profile'
export const PATH_PROVINCE = '/admin/v1/region/provinces'
export const RESEND_SET_PASSWORD = '/admin/v1/employees/resend-set-password'
export const USER_ROLE = '/admin/v1/user-roles/list'
export const EMPLOYEE_BULK = '/admin/v1/employees/bulk'
export const EMPLOYEE_DOWNLOAD_TEMPLATE = '/admin/v1/employees/download-csv'

export const LIST_EMPLOYEE_KEY = 'LIST_EMPLOYEE_KEY'
export const DETAIL_EMPLOYEE_KEY = 'DETAIL_EMPLOYEE_KEY'

export const LIST_MEMBER_KEY = 'LIST_MEMBER_KEY'
export const DETAIL_MEMBER_KEY = 'DETAIL_MEMBER_KEY'

export const LIST_ROLE_KEY = 'LIST_ROLE_KEY'

export const PATH_PROVINCE_KEY = 'PATH_PROVINCE_KEY'
export const PATH_TIERS_KEY = 'PATH_TIERS_KEY'
export const PATH_CITY_KEY = 'PATH_CITY_KEY'
export const ACCESS_ADMIN_KEY = 'ACCESS_ADMIN_KEY'
export const ACCESS_TEMPLATE_KEY = 'ACCESS_TEMPLATE_KEY'
