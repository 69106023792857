import {defaultBreadcrumbs} from 'src/app/models/screen-prop'

const TransactionScreens = {
  TRANSACTION_SCREENS: {
    PATH: '/transaction',
    TITLE: 'Konfigurasi Pembayaran',
    TITLE_ALT: 'Konfigurasi Pembayaran',
    // PERMISSION: "category.read",
    breadcrumbs: defaultBreadcrumbs,
  },
  PAYMENT_METHOD: {
    PATH: '/transaction/payment-method',
    TITLE: 'Metode Pembayaran',
    TITLE_ALT: 'Metoder Pembayaran',
    PERMISSION: 'metode_pembayaran.read',
    breadcrumbs: defaultBreadcrumbs,
  },
  PAYMENT_METHOD_ADD: {
    PATH: '/transaction/payment-method/add',
    TITLE: 'Tambah Metode Pembayaran',
    TITLE_ALT: 'Tambah Metoder Pembayaran',
    PERMISSION: 'metode_pembayaran.create',
    breadcrumbs: defaultBreadcrumbs,
  },
  PAYMENT_METHOD_EDIT: {
    PATH: '/transaction/payment-method/edit/:id',
    TITLE: 'Edit Metode Pembayaran',
    TITLE_ALT: 'Edit Metoder Pembayaran',
    PERMISSION: 'metode_pembayaran.update',
    breadcrumbs: defaultBreadcrumbs,
  },
  PAYMENT_METHOD_DETAIL: {
    PATH: '/transaction/payment-method/detail/:id',
    TITLE: 'Detail Metode Pembayaran',
    TITLE_ALT: 'Detail Metoder Pembayaran',
    PERMISSION: 'metode_pembayaran.read',
    breadcrumbs: defaultBreadcrumbs,
  },
  TOP_UP: {
    PATH: '/transaction/top-up',
    TITLE: 'Top Up',
    TITLE_ALT: 'Top Up',
    PERMISSION: 'topup.read',
    breadcrumbs: defaultBreadcrumbs,
  },
  TOP_UP_ADD: {
    PATH: '/transaction/top-up/add',
    TITLE: 'Tambah Top Up',
    TITLE_ALT: 'Tambah Top Up',
    PERMISSION: 'topup.create',
    breadcrumbs: defaultBreadcrumbs,
  },
  TOP_UP_EDIT: {
    PATH: '/transaction/top-up/edit/:id',
    TITLE: 'Edit Top Up',
    TITLE_ALT: 'Edit Top Up',
    PERMISSION: 'topup.update',
    breadcrumbs: defaultBreadcrumbs,
  },
  TOP_UP_DETAIL: {
    PATH: '/transaction/top-up/detail/:id',
    TITLE: 'Detail Top Up',
    TITLE_ALT: 'Detail Top Up',
    PERMISSION: 'topup.read',
    breadcrumbs: defaultBreadcrumbs,
  },
}

export default TransactionScreens
