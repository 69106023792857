const AuditTrailScreens = {
  AUDIT_TRAIL: {
    PATH: '/audit-trail',
    TITLE: 'Audit Trail',
    TITLE_ALT: 'Audit Trail',
    PERMISSION: 'audit_trail.read',
  },
  ADD_AUDIT_TRAIL: {
    PATH: '/audit-trail/add',
    TITLE: 'Audit Trail',
    TITLE_ALT: 'Audit Trail',
    PERMISSION: 'audit_trail.read',
  },
}

export default AuditTrailScreens
