/* eslint-disable no-useless-escape */
import {useFormik} from 'formik'
import {FC, useEffect, useState} from 'react'
import {useHistory} from 'react-router-dom'
import GButton from 'src/app/components/Libs/Button/GButton'
import {FormLabel, FormPassword} from 'src/app/components/Libs/Form/Collection'
import {useCancelableFn} from 'src/app/hooks/cancelable-hook'
import * as Yup from 'yup'

import {useHeaderToast} from 'src/app/context/ToastContext'
import {resetPasswordEmployee} from 'src/app/modules/user-management/services/Permission.services'
import {getTitle} from 'src/app/utils/title-utils'
import AuthScreens from '../../Screens'

const CreateNewPasswordEmployee: FC = () => {
  const history = useHistory()
  const url = history.location?.pathname
  const token = url.substring(url.indexOf('/auth/set-password/') + '/auth/set-password/'.length)

  const {addPageToasts} = useHeaderToast()
  const [loading, setLoading] = useState(false)

  const resetSchema = Yup.object().shape({
    password: Yup.string()
      .required('Password wajib diisi')
      .min(
        8,
        'Password harus terdiri dari minimal 8 karakter dan mengandung kombinasi huruf besar dan kecil, angka, dan simbol.'
      )
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
        'Password harus terdiri dari minimal 8 karakter dan mengandung kombinasi huruf besar dan kecil, angka, dan simbol.'
      ),
    new_password: Yup.string()
      .min(
        8,
        'Password harus terdiri dari minimal 8 karakter dan mengandung kombinasi huruf besar dan kecil, angka, dan simbol.'
      )
      .required('Konfirmasi Password Baru wajib diisi')
      .test('equal', 'Password tidak sesuai.', function (v) {
        const ref = Yup.ref('password')
        return v === this.resolve(ref)
      }),
  })

  const initialValues = {
    password: '',
    new_password: '',
  }

  const submitFn = useCancelableFn(async (payload: any, isActive, cancelToken) => {
    try {
      const response = await resetPasswordEmployee(payload)

      if (response.status === 201) {
        setTimeout(() => {
          addPageToasts({
            scheme: 'success',
            text: 'Buat password berhasil',
          })
          formik.setSubmitting(false)
          history.replace(AuthScreens.LOGIN_EMAIL.PATH)
        }, 1000)
      }
    } catch (e: any) {
      const errors = e.response

      if (errors.status >= 400) {
        addPageToasts({scheme: 'danger', text: 'Buat password gagal'})
        formik.setSubmitting(false)
      }
    } finally {
      setLoading(false)
    }
  })

  const formik = useFormik({
    initialValues,
    validateOnBlur: false,
    validateOnChange: true,
    validationSchema: resetSchema,
    onSubmit: (values) => {
      setLoading(true)
      const payload = {
        token: token ? token : '',
        password: values.password,
        confirm_password: values.new_password,
      }
      submitFn(payload)
    },
  })

  useEffect(() => {
    document.title = getTitle('Buat Password')
  }, [])

  return (
    <>
      <div className='w-full'>
        <div className='mb-10 text-center'>
          <div className='mb-4 font-medium text-fs-5'>Buat password</div>
          <div className='text-[#667085] text-xs'>
            Untuk dapat mengakses cms silakan lengkapi kolom password dibawah.
          </div>
        </div>

        <form className='w-full' onSubmit={formik.handleSubmit} noValidate>
          <div className='mb-8'>
            <FormLabel required className='mb-2'>
              Password baru
            </FormLabel>
            <FormPassword
              {...formik.getFieldProps('password')}
              name='password'
              placeholder='Masukkan password baru Anda'
              maxLength={50}
              error={formik.errors.password}
              touched={formik.touched.password}
            />
            {formik.errors.password === undefined && (
              <div className='pt-2'>
                <p className=' text-xs text-[#94A3B8]'>
                  Password harus terdiri dari minimal 8 karakter dan mengandung kombinasi huruf
                  besar dan kecil, angka, dan simbol.
                </p>
              </div>
            )}
          </div>

          <div className='mb-8'>
            <FormLabel required className='mb-2'>
              Konfirmasi password baru
            </FormLabel>
            <FormPassword
              {...formik.getFieldProps('new_password')}
              name='new_password'
              placeholder='Konfirmasi password baru Anda'
              maxLength={50}
              error={formik.errors.new_password}
              touched={formik.touched.new_password}
            />
          </div>

          <GButton
            type='submit'
            size='large'
            className='w-full bg-danger border-none'
            loading={loading}
          >
            Simpan
          </GButton>
        </form>
      </div>
    </>
  )
}

export default CreateNewPasswordEmployee
