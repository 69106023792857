import {FC} from 'react'
import en from 'react-date-object/locales/gregorian_en'
import type {CalendarProps} from 'react-multi-date-picker'
import DatePicker from 'react-multi-date-picker'
import TimePicker from 'react-multi-date-picker/plugins/time_picker'
import 'react-multi-date-picker/styles/colors/red.css'
import {FormText, FormTextProps} from 'src/app/components/Libs/Form/Collection'

const CustomInput: FC<FormTextProps> = ({
  onFocus,
  className,
  value,
  placeholder,
  error,
  touched,
  disabled,
  ...rest
}) => {
  return (
    <FormText
      onFocus={onFocus}
      value={value}
      appendInnerIcon='IconCalendar'
      clickAppendInnerIcon={onFocus as () => void}
      appendInnerClassname='text-gray-400'
      readOnly
      placeholder={placeholder}
      error={error}
      touched={touched}
      className={className}
      disabled={disabled}
      // {...rest}
    />
  )
}

export interface FormDateProps extends CalendarProps {
  placeholder?: string
  error?: string
  touched?: boolean
  format?: string
  showTimePicker?: boolean
  className?: string
}

const FormDatepicker: FC<FormDateProps> = ({
  placeholder = 'Enter date',
  error,
  touched,
  format = 'DD/MM/YYYY',
  showTimePicker = false,
  className,
  disabled,
  ...rest
}) => {
  const weekDays = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa']

  return (
    <div className={className}>
      <DatePicker
        portal
        containerStyle={{
          width: '100%',
        }}
        render={
          <CustomInput
            placeholder={placeholder}
            error={error}
            touched={touched}
            inputClassName={className}
            disabled={disabled}
          />
        }
        weekDays={weekDays}
        format={format}
        plugins={showTimePicker ? [<TimePicker position='bottom' hideSeconds key={'time'} />] : []}
        showOtherDays
        className='custom-calendar red'
        monthYearSeparator=' '
        locale={en}
        {...rest}
      />
    </div>
  )
}

export {FormDatepicker}
