import {FC} from 'react'
import {Tooltip} from 'react-tooltip'

const TooltipView: FC = () => {
  return (
    <>
      <Tooltip id='blance-tooltip' className='tooltip-custom'>
        Balance = Earned - Used - Expired
      </Tooltip>
    </>
  )
}

export default TooltipView
