import {defaultBreadcrumbs} from 'src/app/models/screen-prop'

const ReviewAndFeedbackScreens = {
  RIVIEWANDFEEDBACK: {
    PATH: '/review-and-feedback',
    TITLE: 'Review & Feedback',
    TITLE_ALT: 'Review & Feedback',
    // PERMISSION: "category.read",
    breadcrumbs: defaultBreadcrumbs,
  },

  FEEDBACKAPPS: {
    PATH: '/review-and-feedback/feedback',
    TITLE: 'Feedback Apps',
    TITLE_ALT: 'Feedback Apps',
    PERMISSION: 'feedback_apps.read',
    breadcrumbs: defaultBreadcrumbs,
  },
  FEEDBACKAPPS_DETAIL: {
    PATH: '/review-and-feedback/feedback/detail/:id',
    TITLE: 'Detail Feedback Apps',
    TITLE_ALT: 'Detail Feedback Apps',
    // PERMISSION: "category.read",
    breadcrumbs: defaultBreadcrumbs,
  },
  RIVIEW: {
    PATH: '/review-and-feedback/review-reting',
    TITLE: 'Review/Rating',
    TITLE_ALT: 'Review/Rating',
    PERMISSION: 'review_rating.read',
    breadcrumbs: defaultBreadcrumbs,
  },
  RIVIEW_DETAIL: {
    PATH: '/review-and-feedback/review-reting/detail/:id',
    TITLE: 'Detail Review/Rating',
    TITLE_ALT: 'Detail Review/Rating',
    PERMISSION: 'review_rating.read',
    breadcrumbs: defaultBreadcrumbs,
  },
}

export default ReviewAndFeedbackScreens
