import {defaultBreadcrumbs} from 'src/app/models/screen-prop'

const BrandAndOutletScreens = {
  BRANDSANDOUTLET: {
    PATH: '/outlet',
    TITLE: 'Outlet',
    TITLE_ALT: 'Outlet',
    breadcrumbs: defaultBreadcrumbs,
  },
  BRANDS: {
    PATH: '/outlet/brand',
    TITLE: 'Brand',
    TITLE_ALT: 'Brand',
    PERMISSION: 'brand.read',
    breadcrumbs: defaultBreadcrumbs,
  },
  BRANDS_ADD: {
    PATH: '/outlet/brand/add',
    TITLE: 'Buat Data Brand',
    TITLE_ALT: 'Buat Data Brand',
    PERMISSION: 'brand.create',
    breadcrumbs: defaultBreadcrumbs,
  },
  BRANDS_EDIT: {
    PATH: '/outlet/brand/edit/:id',
    TITLE: 'Edit Data Brand',
    TITLE_ALT: 'Edit Data Brand',
    PERMISSION: 'brand.update',
    breadcrumbs: defaultBreadcrumbs,
  },
  BRANDS_DETAIL: {
    PATH: '/outlet/brand/detail/:id',
    TITLE: 'Detail Data Brand',
    TITLE_ALT: 'Detail Data Brand',
    PERMISSION: 'brand.read',
    breadcrumbs: defaultBreadcrumbs,
  },
  OUTLET: {
    PATH: '/outlet',
    TITLE: 'Outlet',
    TITLE_ALT: 'Outlet',
    PERMISSION: 'outlet.read',
    breadcrumbs: defaultBreadcrumbs,
  },
  OUTLET_ADD: {
    PATH: '/outlet/add',
    TITLE: 'Buat Data Outlet',
    TITLE_ALT: 'Buat Data Outlet',
    PERMISSION: 'outlet.create',
    breadcrumbs: defaultBreadcrumbs,
  },
  OUTLET_DETAIL: {
    PATH: '/outlet/detail/:id',
    TITLE: 'Detail Data Outlet',
    TITLE_ALT: 'Detail Data Outlet',
    PERMISSION: 'outlet.read',
    breadcrumbs: defaultBreadcrumbs,
  },
  OUTLET_EDIT: {
    PATH: '/outlet/edit/:id',
    TITLE: 'Edit Data Outlet',
    TITLE_ALT: 'Edit Data Outlet',
    PERMISSION: 'outlet.update',
    breadcrumbs: defaultBreadcrumbs,
  },
}

export default BrandAndOutletScreens
