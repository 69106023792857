import clsx from 'clsx'
import {FC} from 'react'
import {usePageData} from 'src/app/context/PageDataContext'
import GBreadcrumbs from '../../Libs/Breadcrumbs/GBreadcrumbs'

const DefaultTitle: FC = () => {
  const {pageTitle} = usePageData()

  return (
    <div data-testid='id-page-title'>
      <div className={clsx('mb-0 flex items-start flex-col')}>
        {pageTitle && (
          <div
            className='mb-1 overflow-hidden font-medium break-all text-fs-7 text-ellipsis'
            style={{WebkitLineClamp: 1}}
          >
            {pageTitle}
          </div>
        )}

        <GBreadcrumbs />
      </div>
    </div>
  )
}

export {DefaultTitle}
