import { FC, MouseEventHandler } from "react";
import GButton from "../Button/GButton";

interface ModalFooterProps {
  handleSuccess?: MouseEventHandler;
  handleClose?: MouseEventHandler;
  positiveLabel?: string;
  negativeLabel?: string;
  loading?: boolean;
}

const ModalFooter: FC<ModalFooterProps> = ({
  handleSuccess,
  handleClose,
  positiveLabel,
  negativeLabel,
  loading,
}) => {
  return (
    <div className="flex items-center justify-end px-6 py-5 border-t border-neutral-30 gap-4">
      {negativeLabel && (
        <GButton
          onClick={handleClose}
          type="button"
          variant="outline"
          className="min-w-[138px] bg-white text-danger-dark-1 border-danger-dark-1"
          disabled={loading}
          data-testid="close-button"
        >
          {negativeLabel ?? "No"}
        </GButton>
      )}

      <GButton
        onClick={handleSuccess}
        className="mr-4 min-w-[138px] border-danger-dark-1 bg-gradient-to-r  from-[#CF1E26] to-[#F43F5E] border-0 text-white"
        type="button"
        loading={loading}
        disabled={loading}
        data-testid="success-button"
      >
        {positiveLabel ?? "Yes"}
      </GButton>
    </div>
  );
};

export default ModalFooter;
