import {defaultBreadcrumbs} from 'src/app/models/screen-prop'

const SettingsScreens = {
  // FAQ
  SETTINGS: {
    PATH: '/setting',
    TITLE: 'Settings',
    TITLE_ALT: 'Settings',
    // PERMISSION: "user.read",
    breadcrumbs: defaultBreadcrumbs,
  },
  // FAQ
  FAQ: {
    PATH: '/setting/faq',
    TITLE: 'FAQ',
    TITLE_ALT: 'FAQ',
    PERMISSION: 'faq.read',
    breadcrumbs: defaultBreadcrumbs,
  },
  ADD_FAQ: {
    PATH: '/setting/category-faq/add',
    TITLE: 'Add Kategori FAQ',
    TITLE_ALT: 'Add Kategori FAQ',
    PERMISSION: 'faq.create',
    breadcrumbs: defaultBreadcrumbs,
  },
  DETAIL_FAQ: {
    PATH: '/setting/faq/detail/:id',
    TITLE: 'Detail Kategori FAQ',
    TITLE_ALT: 'Detail Kategori FAQ',
    PERMISSION: 'faq.read',
    breadcrumbs: defaultBreadcrumbs,
  },
  EDIT_FAQ: {
    PATH: '/setting/faq/edit/:id',
    TITLE: 'Edit Kategori FAQ',
    TITLE_ALT: 'Edit Kategori FAQ',
    PERMISSION: 'faq.update',
    breadcrumbs: defaultBreadcrumbs,
  },
  // content faq
  CONTENT_FAQ: {
    PATH: '/setting/content-faq',
    TITLE: ' Konten FAQ',
    TITLE_ALT: 'Konten FAQ',
    PERMISSION: 'faq.read',
    breadcrumbs: defaultBreadcrumbs,
  },
  ADD_CONTENT_FAQ: {
    PATH: '/setting/content-faq/add',
    TITLE: 'Buat Konten FAQ',
    TITLE_ALT: 'Buat Konten FAQ',
    PERMISSION: 'faq.create',
    breadcrumbs: defaultBreadcrumbs,
  },
  EDIT_CONTENT_FAQ: {
    PATH: '/setting/content-faq/edit/:id',
    TITLE: 'Edit Konten FAQ',
    TITLE_ALT: 'Edit Konten FAQ',
    PERMISSION: 'faq.update',
    breadcrumbs: defaultBreadcrumbs,
  },
  DETAIL_CONTENT_FAQ: {
    PATH: '/setting/content-faq/detail/:id',
    TITLE: 'Detail Konten FAQ',
    TITLE_ALT: 'Detail Konten FAQ',
    PERMISSION: 'faq.read',
    breadcrumbs: defaultBreadcrumbs,
  },
  // category faq
  CATEGORY_FAQ: {
    PATH: '/setting/category-faq',
    TITLE: ' Konten FAQ',
    TITLE_ALT: 'Konten FAQ',
    PERMISSION: 'faq.read',
    breadcrumbs: defaultBreadcrumbs,
  },
  ADD_CATAGORY_FAQ: {
    PATH: '/setting/category-faq/add',
    TITLE: 'Buat kategori FAQ',
    TITLE_ALT: 'Buat kategori FAQ',
    PERMISSION: 'faq.create',
    breadcrumbs: defaultBreadcrumbs,
  },
  EDIT_CATAGORY_FAQ: {
    PATH: '/setting/category-faq/edit/:id',
    TITLE: 'Edit kategori FAQ',
    TITLE_ALT: 'Edit kategori FAQ',
    PERMISSION: 'faq.update',
    breadcrumbs: defaultBreadcrumbs,
  },
  DETAIL_CATAGORY_FAQ: {
    PATH: '/setting/category-faq/detail/:id',
    TITLE: 'Detail kategori FAQ',
    TITLE_ALT: 'Detail kategori FAQ',
    PERMISSION: 'faq.read',
    breadcrumbs: defaultBreadcrumbs,
  },
  // Contact US
  CONTACT_US: {
    PATH: '/setting/contact-us',
    TITLE: 'Hubungi Kami',
    TITLE_ALT: 'Hubungi Kami',
    PERMISSION: 'hubungi-kami.create',
    breadcrumbs: defaultBreadcrumbs,
  },
  // Privacy Policy
  PRIVACY_POLICY: {
    PATH: '/setting/privacy-policy',
    TITLE: 'Kebijakan Privasi',
    TITLE_ALT: 'Kebijakan Privasi',
    PERMISSION: 'kebijakan-privasi.read',
    breadcrumbs: defaultBreadcrumbs,
  },

  // Syarat dan Ketentuan
  TERMS_AND_CONDITIONS: {
    PATH: '/setting/tnc',
    TITLE: 'Syarat dan Ketentuan',
    TITLE_ALT: 'Syarat dan Ketentuan',
    PERMISSION: 'syarat-dan-ketentuan.read',
    breadcrumbs: defaultBreadcrumbs,
  },
  TERMS_AND_CONDITIONS_CREDIT: {
    PATH: '/setting/credit',
    TITLE: 'Syarat dan Ketentuan Credit',
    TITLE_ALT: 'Syarat dan Ketentuan Credit',
    PERMISSION: 'syarat-dan-ketentuan.read',
    breadcrumbs: defaultBreadcrumbs,
  },
  // Aboutus
  ABOUT_US: {
    PATH: '/setting/about-us',
    TITLE: 'Tentang Kami',
    TITLE_ALT: 'Tentang Kami',
    PERMISSION: 'tentang-kami.read',
    breadcrumbs: defaultBreadcrumbs,
  },
  BANNER: {
    PATH: '/setting/banner',
    TITLE: 'Banner Promo',
    TITLE_ALT: 'Banner Promo',
    PERMISSION: 'banner_promo.read',
    breadcrumbs: defaultBreadcrumbs,
  },
  ADD_BANNER: {
    PATH: '/setting/banner/add',
    TITLE: 'Tambah Banner Promo',
    TITLE_ALT: 'Tambah Banner Promo',
    PERMISSION: 'banner_promo.create',
    breadcrumbs: defaultBreadcrumbs,
  },
  EDIT_BANNER: {
    PATH: '/setting/banner/edit/:id',
    TITLE: 'Edit Banner Promo',
    TITLE_ALT: 'Edit Banner Promo',
    PERMISSION: 'banner_promo.update',
    breadcrumbs: defaultBreadcrumbs,
  },
  DETAIL_BANNER: {
    PATH: '/setting/banner/detail/:id',
    TITLE: 'Detail Banner Promo',
    TITLE_ALT: 'Detail Banner Promo',
    PERMISSION: 'banner_promo.read',
    breadcrumbs: defaultBreadcrumbs,
  },
}

export default SettingsScreens
