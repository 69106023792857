import clsx from 'clsx'
import React, {useMemo} from 'react'
import {FileRejection, useDropzone} from 'react-dropzone'
import GButton from '../../Button/GButton'
import GIcon from '../../Icon/GIcon'

export interface FormUploadImageProps {
  onDrop: (acceptedFiles: File[], rejectedFiles: FileRejection[]) => void
  onClear: () => void
  preview: string | undefined
  title: string
  message: string
  disabled?: boolean
  required?: boolean
  error?: string
  touched?: boolean
}

const FormUploadImage: React.FC<FormUploadImageProps> = ({
  onDrop,
  onClear,
  preview,
  title,
  message,
  disabled,
  required,
  error,
  touched,
}) => {
  const {getRootProps, getInputProps, isDragActive} = useDropzone(
    useMemo(
      () => ({
        onDrop,
        multiple: false,
        accept: ['.jpg', '.jpeg', '.png'],
      }),
      [onDrop]
    )
  )

  return (
    <div className='w-100'>
      <div
        {...(disabled ? {} : getRootProps())}
        className={clsx('w-100 rounded-lg', {'border border-danger': error && touched})}
      >
        {disabled ? null : <input {...getInputProps()} />}
        <div className='w-full rounded-lg bg-neutral-10'>
          {preview ? (
            <div className='relative h-[168px] overflow-hidden'>
              <img alt={title} src={preview} className='object-contain w-full h-full rounded-lg' />

              <GButton
                variant='action'
                color='warning'
                className='absolute z-10'
                type='button'
                style={{left: 16, bottom: 12, boxShadow: '0px 8px 12px rgba(33, 37, 41, 0.24)'}}
                actionIcon='IconEdit'
              />

              <GButton
                data-testid='gbutton-test'
                variant='action'
                color='danger'
                className='absolute z-10'
                type='button'
                onClick={(event) => {
                  event.stopPropagation()
                  onClear()
                }}
                actionIcon='IconDelete'
                style={{left: 60, bottom: 12, boxShadow: '0px 8px 12px rgba(33, 37, 41, 0.24)'}}
              />
            </div>
          ) : (
            <div className='w-full flex flex-col justify-center items-center h-[168px]'>
              <GIcon icon='IconImagePlaceHolder' className='mb-3' />
              <div className='mb-3 text-center text-fs-10 text-neutral-70'>
                {isDragActive ? `Drop the files here ...` : message}
              </div>
              <GButton type='button' variant='outline' size='small'>
                Select Image
              </GButton>
            </div>
          )}
        </div>
      </div>
      {touched && error && <div className='mt-2 font-medium text-danger text-fs-10'>{error}</div>}
    </div>
  )
}

export {FormUploadImage}
